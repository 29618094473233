@import "~bootstrap/scss/bootstrap";

// No media query necessary for xs breakpoint as it's effectively `@media (min-width: 0) { ... }`
@include media-breakpoint-up(sm) {

}
@include media-breakpoint-up(md) {

}
@include media-breakpoint-up(lg) {

}
@include media-breakpoint-up(xl) {

}
@include media-breakpoint-up(xxl) {

}



.carousel-indicators [data-bs-target] { 
    background: #D4D6DB;
    box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.08);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    padding: 0px;
    border: 0px;
}

.carousel-indicators .active {
    background: var(--red);
}

.carousel-indicators { 
    margin-bottom: -40px;
}


@media (max-width: 1199.8px) {
    .svg-arrow {
        position: absolute;
        bottom: 14px;
    }
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;
  
    > .col,
    > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }

