/* Tag default */
.tag_container {
    display: inline-flex;
    flex-direction: row;
    height: 32px;
    padding: 4px;
    border-radius: 28px;
    overflow-y: auto;
    cursor: default;
    transition: 0.25s all linear;
    white-space: nowrap;
}

.tag_text {
    padding: 2px 4px;
}

/* Tag színek szerinti CSS változatok */
.tag_orange {
    background: var(--orange-bg);
    color: var(--orange);
}

.tag_blue {
    background: var(--blue-bg);
    color: var(--blue);
}

.tag_purple {
    background: var(--purple-bg);
    color: var(--purple);
 }

 .tag_yellow {
    background: var(--yellow-bg);
    color: var(--yellow);
 }

 .tag_pink {
    background: var(--pink-bg);
    color: var(--pink);
 }

 .tag_lightblue {
    background: var(--light-blue-bg);
    color: var(--light-blue);
 }

 .tag_green {
    background: var(--green-bg);
    color: var(--green);
 }

 .tag_grey {
    background: #F5F6F7;
    color: #646568;
 }

 .tag_red {
    /* #F40009 */
    background: var(--red);
    /* #FFFFFF */
    color: #FFFFFF;
 }

 .tag_lightred {
   /* #F40009 */
   background: rgba(244, 0, 0, 0.1);
   /* #FFFFFF */
   color: var(--red);
}
 .tag_black {
    /* #12072A */
    background: #12072A;
    /* #FFFFFF */
    color: #FFFFFF;
 }

/* Tag ikon beállítások  */
.ejicon-communication {
    display:flex;
 }

/* Tag csak ikon (just_icon) szinek szerinti változatok */
.just_icon {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    align-content: center;
    align-items: center;
    justify-content: center;
    display:flex;
}

.just_icon>.ejicon-communication:before {
    font-size: 10.5px;
 }

 .just_icon>.ejicon-money-bill:before {
    font-size: 10px;
 }

 .just_icon>.ejicon-label-tag:before {
    font-size: 14px;
 }

 .just_icon>.ejicon-bullhorn:before  {
    font-size: 14px;
 }

 .just_icon>.ejicon-self-knowledge:before {
    font-size: 14px;
 }

/* Tag bal ikon (left_icon) általános */
.tag_lefticon {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    align-content: center;
    align-items: center;
    justify-content: center;
    display:flex;
 }

 /* Tag bal ikon (left_icon) színek szerinti változatok */
 /* orange */
 .tag_orange>.tag_lefticon {
    background: var(--orange);
 }

 .tag_orange>.tag_lefticon>.ejicon-communication {
    display:flex;
 }

 .tag_orange>.tag_lefticon>div:before {
    font-size: 10.5px;
    color: var(--orange-bg);
    display:flex;
}

/* blue */
.tag_blue>.tag_lefticon {
    background: var(--blue);
 }

 .tag_blue>.tag_lefticon>.ejicon-money-bill {
    display:flex;
 }

 .tag_blue>.tag_lefticon>div:before {
    font-size: 10px;
    color: var(--blue-bg);
    display:flex;
}

/* purple */
.tag_purple>.tag_lefticon {
    background: var(--purple);
 }

 .tag_purple>.tag_lefticon>.ejicon-label-tag {
    display:flex;
 }

 .tag_purple>.tag_lefticon>div:before {
    font-size: 14px;
    color: var(--purple-bg);
    display:flex;
}

/* yellow */
.tag_yellow>.tag_lefticon {
     background: var(--yellow);
 }

 .tag_yellow>.tag_lefticon>.ejicon-bullhorn {
    display:flex;
 }

 .tag_yellow>.tag_lefticon>div:before {
    font-size: 14px;
    color: var(--yellow-bg);
    display:flex;
}

/* pink */
.tag_pink>.tag_lefticon {
    background: var(--pink);
 }

 .tag_pink>.tag_lefticon>.ejicon-self-knowledge {
    display:flex;
 }

 .tag_pink>.tag_lefticon>div:before {
    font-size: 14px;
    color: var(--pink-bg);
    display:flex;
}

/* lighblue */
.tag_lightblue>.tag_lefticon {
    background: var(--light-blue);
 }

 .tag_lightblue>.tag_lefticon>.ejicon-communication {
    display:flex;
 }

 .tag_lightblue>.tag_lefticon>div:before {
    font-size: 10.5px;
    color: var(--light-blue-bg);
    display:flex;
}

/* green */
.tag_green>.tag_lefticon {
    background: var(--green);
 }

 .tag_green>.tag_lefticon>.ejicon-communication {
    display:flex;
 }

 .tag_green>.tag_lefticon>div:before {
    font-size: 10.5px;
    color: var(--green-bg);
    display:flex;
}

.tag_green>.tag_lefticon>.ejicon-tick {
   display:flex;
}

.tag_green>.tag_lefticon>div:before {
   font-size: 10.5px;
   color: var(--green-bg);
   display:flex;
}

/* grey */
.tag_grey>.tag_lefticon {
    background: #646568;
 }

 .tag_grey>.tag_lefticon>.ejicon-communication {
    display:flex;
 }

 .tag_grey>.tag_lefticon>div:before {
    font-size: 10.5px;
    color: #F5F6F7;
    display:flex;
}

/* lightred */
.tag_lightred>.tag_lefticon {
   background: rgba(244, 0, 0, 0.1);
}

.tag_lightred>.tag_lefticon>.ejicon-communication {
   display:flex;
}

.tag_lightred>.tag_lefticon>div:before {
   font-size: 10.5px;
   color: var(--red);
   display:flex;
}

/* red */
.tag_red>.tag_lefticon {
    background: #FFFFFF;
 }

 .tag_red>.tag_lefticon>.ejicon-communication {
    display:flex;
 }

 .tag_red>.tag_lefticon>div:before {
    font-size: 10.5px;
    color: var(--red);
    display:flex;
}

/* black */
 .tag_black>.tag_lefticon {
    background: #FFFFFF;
    align-items: center;
 }

 .tag_black>.tag_lefticon>.ejicon-communication {
    display:flex;
    
 }

 .tag_black>.tag_lefticon>div:before {
    font-size: 10.5px;
    color: #12072A;
    display:flex;
}



/* Tag exit right icon szinek szerinti változatok */
.tag_righticon {
    align-content: center;
    align-items: center;
    justify-content: center;
    display:flex;
    padding: 2px 6px;
}

.ejicon-close::before{
    transform: rotate(45deg);
}
.faded_tag {
   background: linear-gradient(270deg, #FFFFFF 0%, #FFFFFF 39.2%, rgba(255, 255, 255, 0) 100%);
}