.icon_emoji_smile {
    background: url('../../../assets/icons/emoji/smile.png');
    width: 40px;
    height: 41px;
    display: block;
    /* Other styles here */
}

.icon_emoji_applause {
    background: url('../../../assets/icons/emoji/applause.png');
    width: 40px;
    height: 41px;
    display: block;
    /* Other styles here */
}

.icon_emoji_beach {
    background: url('../../../assets/icons/emoji/beach.png');
    width: 40px;
    height: 41px;
    display: block;
    /* Other styles here */
}

.icon_emoji_books {
    background: url('../../../assets/icons/emoji/books.png');
    width: 40px;
    height: 41px;
    display: block;
    /* Other styles here */
}

.icon_emoji_bulb {
    background: url('../../../assets/icons/emoji/bulb.png');
    width: 40px;
    height: 41px;
    display: block;
    /* Other styles here */
}

.icon_emoji_calendar {
    background: url('../../../assets/icons/emoji/calendar.png');
    width: 40px;
    height: 41px;
    display: block;
    /* Other styles here */
}

.icon_emoji_celebration {
    background: url('../../../assets/icons/emoji/celebration.png');
    width: 24px;
    height: 25px;
    display: block;
    /* Other styles here */
}

.icon_emoji_clock {
    background: url('../../../assets/icons/emoji/clock.png');
    width: 32px;
    height: 33px;
    display: block;
    /* Other styles here */
}

.icon_emoji_folder {
    background: url('../../../assets/icons/emoji/folder.png');
    width: 30px;
    height: 31px;
    display: block;
    /* Other styles here */
}

.icon_emoji_note {
    background: url('../../../assets/icons/emoji/note.png');
    width: 30px;
    height: 31px;
    display: block;
    /* Other styles here */
}

.icon_emoji_notebook {
    background: url('../../../assets/icons/emoji/notebook.png');
    width: 40px;
    height: 41px;
    display: block;
    /* Other styles here */
}

.icon_emoji_paper {
    background: url('../../../assets/icons/emoji/paper.png');
    width: 30px;
    height: 31px;
    display: block;
    /* Other styles here */
}
.icon_emoji_pray {
    background: url('../../../assets/icons/emoji/pray.png');
    width: 24px;
    height: 25px;
    display: block;
    /* Other styles here */
}