/* MOBILE */
@media (max-width: 575.98px) {
	.partner-card {
		padding: 10px;
		box-shadow: var(--shadow-light);
		border-radius: 48px;
		background: var(--white);
		align-items: center;
		flex-direction: column;
	}
	.partner-card-data {
		flex-direction: column;
	}
	.partner-card-header {
		flex-direction: column;
		margin-top: 32px;
		margin-bottom: 32px;
	}
	.partner-card-image img {
		max-width: 100%;
		border-radius: 22px;
		overflow: hidden;
	}

	.partner-socials {
		display: flex;
		justify-content: center;
		order: 3;
	}
	.partner-socials > * {
		margin: 0 16px;
	}

	.partner-card-event-card {
		background: var(--medium-dirt);
		border-radius: 24px;
		padding: 24px;
		flex: 0;
		display: inline-flex;
		flex-direction: column;
	}
	.partner-card-event-cards {
		margin: 16px 0;
	}
}

/* TABLE */
@media (min-width: 576px) and (max-width: 1199.98px) {
	.partner-card {
		padding: 24px;
		box-shadow: var(--shadow-light);
		border-radius: 48px;
		background: var(--white);
		align-items: center;
		flex-direction: column;
		width: 100%;
	}
	.partner-card-data {
		flex-direction: column;
	}
	.partner-card-header {
		flex-direction: column;
		margin: 0;
		padding: 0;
		margin-top: 32px;
		margin-bottom: 32px;
	}
	.partner-card-header * {
		padding-left: 0;
		padding-right: 0;
		margin-left: 0;
		margin-right: 0;
	}
	.partner-card-image img {
		max-width: 100%;
		border-radius: 22px;
		overflow: hidden;
	}

	.partner-socials {
		display: flex;
		justify-content: center;
		order: 3;
	}
	.partner-socials > * {
		margin: 0 16px;
	}

	.partner-card-event-card {
		background: var(--medium-dirt);
		border-radius: 24px;
		padding: 24px;
		flex: 0;
		display: inline-flex;
		flex-direction: column;
	}
	.partner-card-event-cards {
		margin: 16px 0;
	}
}

/* DESKTOP */
@media (min-width: 1200px) {
	.partner-card {
		padding: 32px;
		box-shadow: var(--shadow-light);
		border-radius: 48px;
		background: var(--white);
		align-items: center;
	}

	.partner-card-image img {
		max-width: 100%;
		border-radius: 22px;
		overflow: hidden;
	}

	.partner-card-header {
		flex-wrap: wrap;
	}
	.partner-card-data {
		margin-left: 32px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.partner-socials {
		display: block;
		max-width: 200px;
		text-align: right;
	}

	.partner-card-text {
		margin: 20px 0;
		min-width: 100%;
	}

	.partner-card-events {
		min-height: 180px;
	}

	.partner-card-event-cards {
		margin: 20px 0;
	}
	.partner-card-event-card {
		background: var(--medium-dirt);
		border-radius: 24px;
		padding: 24px;
		flex: 0;
		display: inline-flex;
		flex-direction: column;
	}
} /*
	BASE ELEMENTS
*/
a {
	text-decoration: none;
}

/* LAYOUT */
html {
	overflow-x: hidden;
	position: relative;
	min-height: 100%;
	height: 100%;
	max-width: 100%;
}
body {
	font-size: 16px;
	position: relative;
	min-height: 100%;
	background: #e5e5e5;
	font-family: 'Inter';
	background-color: var(--bs-body-bg);
}
#root {
	position: relative;
	min-height: 100%;
	width: 100%;
}
.App {
	position: relative;
	left: 0;
	width: 100%;
	transition: 0.25s all linear;
	overflow-x: hidden;
}

.cc-content {
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: 50vh;
	z-index: 2;
}
.cc-popup-ontainer {
	position: fixed;
	z-index: -100;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	display: none;
}

/* HELPERS */

.hide {
	display: none;
}

.left-top {
	left: 0;
	top: 0;
}
.right-top {
	right: 0;
	top: 0;
}

.order-last {
	order: 9999;
}

.coca-container {
	transition: 0.25s all linear;
}
.coca-container {
	padding: 0 var(--cc-gap);
}
.cc-scrollbarless {
	scrollbar-width: none;
	-ms-overflow-style: none;
	overflow-x: scroll;
}
.cc-scrollbarless::-webkit-scrollbar {
	display: none;
}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
	z-index: 1;
	overflow: hidden;
	border-radius: 32px;
	box-shadow: var(--shadow-light);
}
.videoWrapper > * {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}
.videoWrapper::before {
	content: '';
	display: block;
	width: 100%;
	height: 100%;
	border: 2px solid var(--medium-gray);
	border-radius: 32px;
	position: absolute;
	z-index: 10;
	pointer-events: none;
}

/* EFFECTS */
.cc-box-shadow {
	box-shadow: var(--shadow-normal);
}

.cc-border-radius-32 {
	border-radius: 32px;
}
.cc-border-radius-36 {
	border-radius: 36px;
}
.cc-border-radius-48 {
	border-radius: 48px;
}

/* TRANSITIONS */
.cc-trans-normal {
	transition: var(--trans-normal);
}
.cc-trans-medium {
	transition: var(--trans-medium);
}

/* BUTTON */
.btn {
	border-radius: 48px;
	padding: 15px 24px;
	font-weight: 600;
	position: relative;
	z-index: 2;
	transition: 0.25s all linear;
	overflow: hidden;
}
.btn:hover {
	color: var(--white);
	transform: translateY(-8px);
	box-shadow: 8px 8px 24px rgba(244, 0, 0, 0.25);
}

.btn::before {
	content: '';
	position: absolute;
	background: #fff;
	opacity: 0.25;
	left: 0%;
	top: 100%;
	width: 100%;
	height: 200%;
	border-radius: 300%;
	transition: 0.25s all linear;
}
.btn:hover::before {
	left: 0%;
	top: 80%;
}

.btn span[class^='ejicon-'] {
	display: inline-block;
	position: relative;
	margin-right: 10px;
}

.btn.btn-circle {
	width: 48px;
	height: 48px;
	padding: 0;
	text-align: center;
}
.btn.btn-small-circle {
	width: 32px;
	height: 32px;
	padding: 0;
	text-align: center;
	flex-direction: column;
	display: inline-flex;
	justify-content: center;
	line-height: 0;
}

.btn span[class^='ejicon-'] {
	display: block;
	margin: auto;
}
.btn span[class^='ejicon-']::before {
	font-size: 16px;
}

.ejicon-podcast::before {
	content: url('../../assets/images/svg/bx-podcast-icon.svg');
	display: inline;
}

.btn.btn-big-circle {
	width: 88px;
	height: 88px;
	flex-direction: column;
	justify-content: center;
	padding: 0;
	text-align: center;
}

.btn.btn-big-circle > * {
	align-self: center;
}

.btn-full {
	width: 100%;
}

/* TABS */

.tab {
	background: var(--medium-dirt);
	border-radius: 16px;
	padding: 7px 16px;
	margin-left: 12px;
	transition: 0.25s all linear;
	position: relative;
	display: inline-block;
	color: var(--dark);
	cursor: pointer;
}
.tab:hover {
	transform: translateY(-8px);
}
.tab.active {
	background: var(--red);
	color: var(--white);
	transform: translateY(-8px);
	box-shadow: 8px 8px 24px rgba(244, 0, 0, 0.25);
}

/* HEADLINES */
h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--dark);
	font-weight: 700;
}

/* MOBILE */
@media (max-width: 575.98px) {
	/* HEADLINES */
	h1 {
		font-size: calc(1.3rem + 1.5vw);
	}
	h2 {
		font-size: calc(1.25rem + 0.4vw);
	}
	h3 {
		font-size: calc(1.2rem + 0.3vw);
	}
	h4 {
		font-size: calc(1.15rem + 0.2vw);
	}
	h5 {
		font-size: calc(1.1rem + 0.1vw);
	}
	h6 {
		font-size: calc(1.05rem + 0.05vw);
	}

	.cc-section {
		position: relative;
		width: 100%;
		flex: 0;
		z-index: 2;
	}
	.order-sm-0 {
		order: 0 !important;
	}
	.order-sm-1 {
		order: 1 !important;
	}
	.order-sm-2 {
		order: 2 !important;
	}
	.order-sm-3 {
		order: 3 !important;
	}
	.order-sm-4 {
		order: 4 !important;
	}
	.order-sm-5 {
		order: 5 !important;
	}
	.order-sm-6 {
		order: 6 !important;
	}
	.order-sm-7 {
		order: 7 !important;
	}
	.order-sm-8 {
		order: 8 !important;
	}
	.order-sm-9 {
		order: 9 !important;
	}
	.order-sm-10 {
		order: 10 !important;
	}
}

/* TABLE */
@media (min-width: 576px) and (max-width: 1199.98px) {
	/* HEADLINES */
	h1 {
		font-size: calc(1.5rem + 1.5vw);
	}
	h2 {
		font-size: calc(1.4rem + 0.4vw);
	}
	h3 {
		font-size: calc(1.3rem + 0.3vw);
	}
	h4 {
		font-size: calc(1.2rem + 0.2vw);
	}
	h5 {
		font-size: calc(1.1rem + 0.1vw);
	}
	h6 {
		font-size: calc(1.05rem + 0.05vw);
	}

	/* HELPERS */
	.cc-section {
		position: relative;
		width: 100%;
		flex: 0;
		z-index: 2;
		margin-bottom: 64px;
	}
	.order-tb-0 {
		order: 0 !important;
	}
	.order-tb-1 {
		order: 1 !important;
	}
	.order-tb-2 {
		order: 2 !important;
	}
	.order-tb-3 {
		order: 3 !important;
	}
	.order-tb-4 {
		order: 4 !important;
	}
	.order-tb-5 {
		order: 5 !important;
	}
	.order-tb-6 {
		order: 6 !important;
	}
	.order-tb-7 {
		order: 7 !important;
	}
	.order-tb-8 {
		order: 8 !important;
	}
	.order-tb-9 {
		order: 9 !important;
	}
	.order-tb-10 {
		order: 10 !important;
	}
}

/* DESKTOP */
@media (min-width: 1200px) {
	/* HEADLINES */

	/* HELPERS */
	.cc-section {
		position: relative;
		width: 100%;
		flex: 0;
		z-index: 2;
		margin-bottom: 32px;
	}
	.cc-section-first {
		top: var(--cc-gap);
	}

	.order-6 {
		order: 6 !important;
	}
	.order-7 {
		order: 7 !important;
	}
	.order-8 {
		order: 8 !important;
	}
	.order-9 {
		order: 9 !important;
	}
	.order-10 {
		order: 10 !important;
	}
}

/* MOBILE */
@media (max-width: 575.98px) {
	.mentor-card-wrapper {
		position: relative;
		justify-content: stretch;
		display: flex;
		flex-direction: column;
		width: calc(100% - 40px);
	}
	.mentor-card {
		position: relative;
		overflow: hidden;
		z-index: 1;
	}
	.mentor-card-back {
		position: absolute;
		z-index: 3;
		top: 100%;
		padding: 24px;
		width: 100%;
		height: 100%;
		background: var(--white);
		transition: var(--trans-normal);
	}

	.mentor-card-wrapper.show-back .mentor-card-back {
		top: 0;
	}

	.mentor-card-front .mentor-badge {
		position: absolute;
		top: 16px;
		height: unset;
		padding-right: 16px;
		text-align: right;
	}
	.mentor-card-front .mentor-card-header {
		position: relative;
		z-index: 1;
	}
	.mentor-image {
	}

	.mentor-card-front .mentor-image img {
		width: 100%;
		transition: var(--trans-normal);
		transform-origin: center;
		transform: scale(1);
	}

	.mentor-card-front:hover .mentor-image img {
		transform: scale(1.015);
	}
	.mentor-card-front .mentor-card-footer {
		position: relative;
		z-index: 2;
		border-radius: 32px 32px 0 0;
		margin-top: -24px;
		padding: 24px;
		background: var(--white);
	}
	.mentor-card-front .mentor-helper {
		margin-bottom: 10px;
	}
	.mentor-card-front .mentor-tags {
		font-weight: 600;
		color: var(--dark);
	}
	.mentor-card .mentor-tags .tag {
		margin-right: 5px;
		margin-bottom: 10px;
	}
}

/* TABLE */
@media (min-width: 576px) and (max-width: 1199.98px) {
	.mentor-card-wrapper {
		position: relative;
		justify-content: stretch;
		display: flex;
		flex-direction: column;
		min-width: 310px;
		margin: 10px;
	}
	.mentor-card {
		position: relative;
		overflow: hidden;
		z-index: 1;
	}
	.mentor-card-back {
		position: absolute;
		z-index: 3;
		top: 100%;
		padding: 24px;
		width: 100%;
		height: 100%;
		background: var(--white);
		transition: var(--trans-normal);
	}

	.mentor-card-wrapper.show-back .mentor-card-back {
		top: 0;
	}

	.mentor-card-front .mentor-badge {
		position: absolute;
		top: 16px;
		height: unset;
		padding-right: 16px;
		text-align: right;
	}
	.mentor-card-front .mentor-card-header {
		position: relative;
		z-index: 1;
	}
	.mentor-image {
	}

	.mentor-card-front .mentor-image img {
		width: 100%;
		transition: var(--trans-normal);
		transform-origin: center;
		transform: scale(1);
	}

	.mentor-card-front:hover .mentor-image img {
		transform: scale(1.015);
	}
	.mentor-card-front .mentor-card-footer {
		position: relative;
		z-index: 2;
		border-radius: 32px 32px 0 0;
		margin-top: -24px;
		padding: 24px;
		background: var(--white);
	}
	.mentor-card-front .mentor-helper {
		margin-bottom: 10px;
	}
	.mentor-card-front .mentor-tags {
		font-weight: 600;
		color: var(--dark);
	}
	.mentor-card .mentor-tags .tag {
		margin-right: 5px;
		margin-bottom: 10px;
	}
}

/* DESKTOP */
@media (min-width: 1200px) {
	.mentor-card-wrapper {
		position: relative;
		justify-content: stretch;
		display: flex;
		flex-direction: column;
		width: 320px;
		z-index: 4;
	}
	.mentor-card {
		position: relative;
		overflow: hidden;
		z-index: 1;
	}
	.mentor-card-back {
		position: absolute;
		z-index: 3;
		top: 100%;
		padding: 24px;
		width: 100%;
		height: 100%;
		background: var(--white);
		transition: var(--trans-normal);
	}

	.mentor-card-wrapper.show-back .mentor-card-back {
		top: 0;
	}

	.mentor-card-front .mentor-badge {
		position: absolute;
		top: 16px;
		height: unset;
		padding-right: 16px;
		text-align: right;
	}
	.mentor-card-front .mentor-card-header {
		position: relative;
		z-index: 1;
	}
	.mentor-image {
	}

	.mentor-card-front .mentor-image img {
		width: 100%;
		transition: var(--trans-normal);
		transform-origin: center;
		transform: scale(1);
	}

	.mentor-card-front:hover .mentor-image img {
		transform: scale(1.015);
	}
	.mentor-card-front .mentor-card-footer {
		position: relative;
		z-index: 2;
		border-radius: 32px 32px 0 0;
		margin-top: -24px;
		padding: 24px;
		background: var(--white);
	}
	.mentor-card-front .mentor-helper {
		margin-bottom: 10px;
	}
	.mentor-card-front .mentor-tags {
		font-weight: 600;
		color: var(--dark);
	}
	.mentor-card .mentor-tags .tag {
		margin-right: 5px;
		margin-bottom: 10px;
	}
} /* MOBILE */
@media (max-width: 575.98px) {
	.mentors-nagy-kep {
		background: url('../../assets/images/svg/ccwave.svg') center 50%
			no-repeat;
		background-size: 100%;
		margin-bottom: 32px;
	}
	.mentors-nagy-kep .nagykep {
		border-radius: 100% 0 100% 100%;
		width: 90vw;
		height: auto;
		position: relative;
		right: calc(-20% - 64px);
		top: -1%;
		z-index: 1;
		pointer-events: none;
	}

	.mentors-header .section-title .col-7 {
		width: 90%;
	}
	.mentors-header .section-text .col {
		width: 100%;
	}

	.mentors-counter-list {
		min-height: unset;
	}
	.mentors-counter-list .cnt {
		margin: 64px 0 32px 0;
		height: unset;
		justify-content: center;
	}
	.mentors-counter-list .splide__track {
		overflow: hidden;
	}

	.mentors-list {
		flex-wrap: wrap;
		justify-content: center;
	}
}

/* TABLE */
@media (min-width: 576px) and (max-width: 1199.98px) {
	.mentors-nagy-kep {
		background: url('../../assets/images/svg/ccwave.svg') center 50%
			no-repeat;
		background-size: 100%;
		margin-bottom: 32px;
	}
	.mentors-nagy-kep .nagykep {
		border-radius: 100% 0 100% 100%;
		width: 90vw;
		height: auto;
		position: relative;
		right: calc(-20% - 64px);
		top: -1%;
		z-index: 1;
		pointer-events: none;
	}

	.mentors-header .section-title .col-7 {
		width: 90%;
	}
	.mentors-header .section-text .col {
		width: 100%;
	}

	.mentors-counter-list {
		min-height: unset;
	}
	.mentors-counter-list .cnt {
		margin: 64px 0 32px 0;
		height: unset;
		justify-content: center;
	}
	.mentors-counter-list .splide__track {
		overflow: hidden;
	}

	.mentors-list {
		flex-wrap: wrap;
		justify-content: center;
	}
}

/* DESKTOP */
@media (min-width: 1200px) {
	.mentors-nagy-kep {
		position: absolute;
		z-index: 3;
		width: 100%;
		height: calc(100vh - var(--cc-gap));
		pointer-events: none;
	}
	.mentors-nagy-kep * {
		height: 100%;
		position: relative;
	}
	.mentors-nagy-kep .nagykep {
		border-radius: 100% 0 100% 100%;
		width: 50vw;
		height: auto;
		position: absolute;
		right: -180px;
		top: -0px;
		max-width: 800px;
	}

	.mentors-header {
		background: url('../../assets/images/svg/ccwave.svg') center 15%
			no-repeat;
		background-size: 100% auto;
	}

	.mentors-counter-list {
		min-height: unset;
	}
	.mentors-counter-list .cnt {
		margin: 64px 0 32px 0;
		height: unset;
		justify-content: center;
	}
	.mentors-counter-list .splide__track {
		overflow: hidden;
	}

	.mentors-list {
		flex-wrap: wrap;
	}
}
.tag {
	border-radius: 16px;
	padding: 2px;
	padding-top: 1px;
	padding-right: 12px;
	display: inline-block;
	font-size: 14x;
	font-weight: 500;
}
.tag .icon {
	display: inline-block;
	padding: 2px;
	width: 24px;
	height: 24px;
	text-align: center;
	border-radius: 16px;
	margin-right: 4px;
	position: relative;
}
.tag .icon span {
	display: block;
	position: relative;
	width: 100%;
	height: 100%;
	text-align: center;
}
.tag .icon span::before {
	font-size: 10px;
	position: relative;
	left: 0;
	top: 0;
} /* MOBILE */
@media (max-width: 575.98px) {
	.cc-modul {
		width: 100%;
	}
	.cc-modul .modul-wrapper {
		border-radius: 32px;
		overflow: hidden;
		transition: 0.25s all linear;
	}
	.cc-modul .modul-wrapper:hover {
		box-shadow: 0 16px 32px rgba(0, 0, 0, 0.08);
		transform: translateY(-8px);
	}

	.cc-modul .modul-header {
		width: 100%;
		height: 100%;
		position: relative;
		z-index: 1;
	}
	.cc-modul .modul-header * {
		-webkit-user-select: none !important;
		-moz-user-select: none !important;
		-ms-user-select: none !important;
		user-select: none !important;
	}
	.cc-modul .modul-status {
		position: absolute;
		z-index: 2;
		display: inline-block;
		height: unset;
		width: unset;
		top: 24px;
		left: 16px;
	}

	.cc-modul .modul-status .status-text {
		position: relative;
		display: inline-block;
		top: -2px;
	}
	.cc-modul .modul-bookmark {
		position: absolute;
		right: 16px;
		top: 16px;
		left: unset;
		z-index: 2;
	}
	.cc-modul .modul-bookmark:hover {
		color: var(--red);
	}
	.cc-modul .modul-image img {
		max-width: 105%;
		cursor: pointer;
		transition: 0.25s all linear;
	}
	.cc-modul:hover .modul-image img {
		transform: scale(1.05);
	}

	.cc-modul .modul-completion {
		width: 90%;
		margin: 0 5%;
		height: 18px;
		z-index: 2;
		top: calc(100% - 56px);
		border-radius: 24px;
		position: absolute;
		display: flex;
		padding: 4px;
	}
	.cc-modul .modul-completion .progress {
		background: none;
		width: calc(100% - 40px);
		height: 10px;
		border-radius: 20px;
		top: 0;
		position: relative;
	}
	.cc-modul .modul-completion .progress-bar {
		background: var(--orange);
	}
	.cc-modul .modul-completion .complete-text {
		color: var(--orange);
		font-size: 12px;
		position: relative;
		top: -3px;
		text-align: right;
		width: 100%;
		margin-right: 2px;
		flex: 0;
		margin-left: 10px;
	}

	.cc-modul .modul-footer {
		border-radius: 32px 32px 0 0;
		margin-top: -16px;
		position: relative;
		z-index: 2;
		padding: 24px;
	}
	.cc-modul .modul-stats,
	.cc-modul .modul-ratings,
	.cc-modul .modul-vievers {
		display: flex;
	}

	.cc-modul .modul-stats {
		margin-top: -10px;
		margin-bottom: 10px;
	}
	.cc-modul .modul-stats * {
		-webkit-user-select: none !important;
		-moz-user-select: none !important;
		-ms-user-select: none !important;
		user-select: none !important;
	}
	.cc-modul .modul-title h5 {
		font-size: 18px;
	}
	.cc-modul .modul-stars,
	.cc-modul .modul-stars-num,
	.cc-modul .modul-vievers {
		flex: 0;
	}

	.cc-modul .modul-stars {
		min-width: 136px;
		padding-right: 0;
		margin-top: -2px;
	}
	.cc-modul .modul-stars-num {
		padding: 0;
	}
	.cc-modul .modul-vievers {
		min-width: 90px;
		text-align: right;
	}
	.cc-modul .modul-vievers .ejicon-user {
		display: inline-block;
		margin-right: 6px;
		position: relative;
		margin-top: 2px;
	}

	.cc-modul .modul-stats .star {
		padding: 1px;
	}
	.cc-modul .modul-stats .star:hover {
		transform: translateY(-4px);
		color: var(--yellow);
		background: none;
		filter: none;
		box-shadow: none;
	}
	.cc-modul .modul-stats .star-empty .ejicon-star::before {
		font-size: 14px;
		color: var(--white);
		-webkit-text-fill-color: white;
		-webkit-text-stroke-width: 2px;
		-webkit-text-stroke-color: var(--yellow);
	}
	.cc-modul .modul-stats .stars {
		display: inline-block;
		margin-left: 10px;
	}
}

/* TABLE */
@media (min-width: 576px) and (max-width: 1199.98px) {
	.cc-modul {
		width: 100%;
	}
	.cc-modul .modul-wrapper {
		border-radius: 32px;
		overflow: hidden;
		transition: 0.25s all linear;
	}
	.cc-modul .modul-wrapper:hover {
		box-shadow: 0 16px 32px rgba(0, 0, 0, 0.08);
		transform: translateY(-8px);
	}

	.cc-modul .modul-header {
		width: 100%;
		height: 100%;
		position: relative;
		z-index: 1;
	}
	.cc-modul .modul-header * {
		-webkit-user-select: none !important;
		-moz-user-select: none !important;
		-ms-user-select: none !important;
		user-select: none !important;
	}
	.cc-modul .modul-status {
		position: absolute;
		z-index: 2;
		display: inline-block;
		height: unset;
		width: unset;
		top: 24px;
		left: 16px;
	}

	.cc-modul .modul-status .status-text {
		position: relative;
		display: inline-block;
		top: -2px;
	}
	.cc-modul .modul-bookmark {
		position: absolute;
		right: 16px;
		top: 16px;
		left: unset;
		z-index: 2;
	}
	.cc-modul .modul-bookmark:hover {
		color: var(--red);
	}
	.cc-modul .modul-image img {
		max-width: 105%;
		cursor: pointer;
		transition: 0.25s all linear;
	}
	.cc-modul:hover .modul-image img {
		transform: scale(1.05);
	}

	.cc-modul .modul-completion {
		width: 90%;
		margin: 0 5%;
		height: 18px;
		z-index: 2;
		top: calc(100% - 56px);
		border-radius: 24px;
		position: absolute;
		display: flex;
		padding: 4px;
	}
	.cc-modul .modul-completion .progress {
		background: none;
		width: calc(100% - 40px);
		height: 10px;
		border-radius: 20px;
		top: 0;
		position: relative;
	}
	.cc-modul .modul-completion .progress-bar {
		background: var(--orange);
	}
	.cc-modul .modul-completion .complete-text {
		color: var(--orange);
		font-size: 12px;
		position: relative;
		top: -3px;
		text-align: right;
		width: 100%;
		margin-right: 2px;
		flex: 0;
		margin-left: 10px;
	}

	.cc-modul .modul-footer {
		border-radius: 32px 32px 0 0;
		margin-top: -16px;
		position: relative;
		z-index: 2;
		padding: 24px;
	}
	.cc-modul .modul-stats,
	.cc-modul .modul-ratings,
	.cc-modul .modul-vievers {
		display: flex;
	}

	.cc-modul .modul-stats {
		margin-top: -10px;
		margin-bottom: 10px;
	}
	.cc-modul .modul-stats * {
		-webkit-user-select: none !important;
		-moz-user-select: none !important;
		-ms-user-select: none !important;
		user-select: none !important;
	}
	.cc-modul .modul-title h5 {
		font-size: 20px;
	}
	.cc-modul .modul-stars,
	.cc-modul .modul-stars-num,
	.cc-modul .modul-vievers {
		flex: 0;
	}

	.cc-modul .modul-stars {
		min-width: 136px;
		padding-right: 0;
		margin-top: -2px;
	}
	.cc-modul .modul-stars-num {
		padding: 0;
	}
	.cc-modul .modul-vievers {
		min-width: 90px;
		text-align: right;
	}
	.cc-modul .modul-vievers .ejicon-user {
		display: inline-block;
		margin-right: 6px;
		position: relative;
		margin-top: 2px;
	}

	.cc-modul .modul-stats .star {
		padding: 1px;
	}
	.cc-modul .modul-stats .star:hover {
		transform: translateY(-4px);
		color: var(--yellow);
		background: none;
		filter: none;
		box-shadow: none;
	}
	.cc-modul .modul-stats .star-empty .ejicon-star::before {
		font-size: 14px;
		color: var(--white);
		-webkit-text-fill-color: white;
		-webkit-text-stroke-width: 2px;
		-webkit-text-stroke-color: var(--yellow);
	}
	.cc-modul .modul-stats .stars {
		display: inline-block;
		margin-left: 10px;
	}
}

/* DESKTOP */
@media (min-width: 1200px) {
	.cc-modul {
		width: 100%;
	}
	.cc-modul .modul-wrapper {
		border-radius: 32px;
		overflow: hidden;
		transition: 0.25s all linear;
	}
	.cc-modul .modul-wrapper:hover {
		box-shadow: 0 16px 32px rgba(0, 0, 0, 0.08);
		transform: translateY(-8px);
	}

	.cc-modul .modul-header {
		width: 100%;
		height: 100%;
		position: relative;
		z-index: 1;
	}
	.cc-modul .modul-header * {
		-webkit-user-select: none !important;
		-moz-user-select: none !important;
		-ms-user-select: none !important;
		user-select: none !important;
	}
	.cc-modul .modul-status {
		position: absolute;
		z-index: 2;
		display: inline-block;
		height: unset;
		width: unset;
		top: 24px;
		left: 16px;
	}

	.cc-modul .modul-status .status-text {
		position: relative;
		display: inline-block;
		top: -2px;
	}
	.cc-modul .modul-bookmark {
		position: absolute;
		right: 16px;
		top: 16px;
		left: unset;
		z-index: 2;
	}
	.cc-modul .modul-bookmark:hover {
		color: var(--red);
	}
	.cc-modul .modul-image img {
		max-width: 105%;
		cursor: pointer;
		transition: 0.25s all linear;
	}
	.cc-modul:hover .modul-image img {
		transform: scale(1.05);
	}

	.cc-modul .modul-completion {
		width: 90%;
		margin: 0 5%;
		height: 18px;
		z-index: 2;
		top: calc(100% - 56px);
		border-radius: 24px;
		position: absolute;
		display: flex;
		padding: 4px;
	}
	.cc-modul .modul-completion .progress {
		background: none;
		width: calc(100% - 40px);
		height: 10px;
		border-radius: 20px;
		top: 0;
		position: relative;
	}
	.cc-modul .modul-completion .progress-bar {
		background: var(--orange);
	}
	.cc-modul .modul-completion .complete-text {
		color: var(--orange);
		font-size: 12px;
		position: relative;
		top: -3px;
		text-align: right;
		width: 100%;
		margin-right: 2px;
		flex: 0;
		margin-left: 10px;
	}

	.cc-modul .modul-footer {
		border-radius: 32px 32px 0 0;
		margin-top: -16px;
		position: relative;
		z-index: 2;
		padding: 24px;
	}
	.cc-modul .modul-stats,
	.cc-modul .modul-ratings,
	.cc-modul .modul-vievers {
		display: flex;
	}

	.cc-modul .modul-stats {
		margin-top: -10px;
		margin-bottom: 10px;
	}
	.cc-modul .modul-stats * {
		-webkit-user-select: none !important;
		-moz-user-select: none !important;
		-ms-user-select: none !important;
		user-select: none !important;
	}

	.cc-modul .modul-stars,
	.cc-modul .modul-stars-num,
	.cc-modul .modul-vievers {
		flex: 0;
	}

	.cc-modul .modul-stars {
		min-width: 136px;
		padding-right: 0;
		margin-top: -2px;
	}
	.cc-modul .modul-stars-num {
		padding: 0;
	}
	.cc-modul .modul-vievers {
		min-width: 90px;
		text-align: right;
	}
	.cc-modul .modul-vievers .ejicon-user {
		display: inline-block;
		margin-right: 6px;
		position: relative;
		margin-top: 2px;
	}

	.cc-modul .modul-stats .star {
		padding: 1px;
	}
	.cc-modul .modul-stats .star:hover {
		transform: translateY(-4px);
		color: var(--yellow);
		background: none;
		filter: none;
		box-shadow: none;
	}
	.cc-modul .modul-stats .star-empty .ejicon-star::before {
		font-size: 14px;
		color: var(--white);
		-webkit-text-fill-color: white;
		-webkit-text-stroke-width: 2px;
		-webkit-text-stroke-color: var(--yellow);
	}
	.cc-modul .modul-stats .stars {
		display: inline-block;
		margin-left: 10px;
	}
}
.events-calendar {
	background: #ffffff;
	box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.08);
	border-radius: 32px;
	padding: 24px;
	align-content: center;
	text-align: center;
	min-width: 252px;
}
/* MOBILE */
@media (max-width: 575.98px) {
}

/* TABLE */
@media (min-width: 576px) and (max-width: 1199.98px) {
}

/* DESKTOP */
@media (min-width: 1200px) {
	.events-header {
		margin-top: calc(112px + 32px);
	}
	.events-sidebar {
		min-width: 300px;
	}
}

/* MOBILE */
@media (max-width: 575.98px) {
	.partners .partners-header {
		background: url('../../assets/images/svg/ccwave.svg') center 15%
			no-repeat;
		background-size: 100% auto;
		margin-bottom: 32px;
	}

	.partners .partners-header .header-column {
		justify-content: center;
		align-items: center;
		flex-order: column;
	}
	.partners .partners-header .header-section-text {
		order: 1;
	}
	.partners .partners-header .section-title {
		margin-bottom: 32px;
	}

	.partners .partner-header-img {
		position: relative;
		height: 100%;
		min-width: 100%;
		right: calc(0px - var(--cc-gap));
		display: flex;
		justify-content: flex-end;
		margin-bottom: 32px;
		order: 0;
	}
	.partners .partner-header-img img {
		max-width: 90%;
	}

	.partners .partners-counter-list {
		min-height: unset;
	}
	.partners .partners-counter-list .cnt {
		margin: 64px 0 32px 0;
		height: unset;
		justify-content: center;
	}
	.partners .partners-counter-list .splide__track {
		overflow: hidden;
	}

	.partners .partners-cards-container {
		flex-direction: column;
	}

	.partners .partners-cards-container .partner-card {
		margin-bottom: 32px;
	}
}

/* TABLE */
@media (min-width: 576px) and (max-width: 1199.98px) {
	.partners .partners-header {
		background: url('../../assets/images/svg/ccwave.svg') center 15%
			no-repeat;
		background-size: 100% auto;
		margin-top: 112px;
	}

	.partners .partners-header .header-column {
		justify-content: center;
		align-items: center;
	}

	.partners .partners-header .header-section-text {
		order: 1;
	}
	.partners .partners-header .section-title {
		margin-bottom: 32px;
	}

	.partners .partner-header-img {
		position: relative;
		height: 100%;
		right: calc(0px - var(--cc-gap));
		display: flex;
		justify-content: flex-end;
		margin-bottom: 32px;
		order: 0;
	}
	.partners .partner-header-img img {
		max-width: 90%;
	}

	.partners .partners-counter-list {
		min-height: unset;
	}
	.partners .partners-counter-list .cnt {
		margin: 64px 0 32px 0;
		height: unset;
		justify-content: center;
	}
	.partners .partners-counter-list .splide__track {
		overflow: hidden;
	}

	.partners .partners-cards-container {
		flex-direction: column;
	}

	.partners .partners-cards-container .partner-card {
		margin-bottom: 32px;
	}
}

/* DESKTOP */
@media (min-width: 1200px) {
	.partners .partners-header {
		background: url('../../assets/images/svg/ccwave.svg') center 15%
			no-repeat;
		background-size: 100% auto;
		margin-bottom: calc(112px + 32px);
	}

	.partners .partners-header .header-column {
		justify-content: center;
		align-items: center;
	}

	.partners .partners-header .header-section-text {
		order: 0;
	}
	.partners .partners-header .section-title {
		margin-bottom: 32px;
	}

	.partners .partner-header-img {
		position: relative;
		height: auto;
		right: calc(0px - var(--cc-gap));
		display: flex;
		justify-content: flex-end;
		order: 1;
	}
	.partners .partner-header-img img {
		max-width: 90%;
	}

	.partners .partners-counter-list {
		min-height: unset;
	}
	.partners .partners-counter-list .cnt {
		margin: 64px 0 32px 0;
		height: unset;
		justify-content: center;
	}
	.partners .partners-counter-list .splide__track {
		overflow: hidden;
	}

	.partners .partners-cards-container {
		flex-direction: column;
	}

	.partners .card-wrapper {
		margin-bottom: 32px;
	}
} /* MOBILE */
@media (max-width: 575.98px) {
}

/* TABLE */
@media (min-width: 576px) and (max-width: 1199.98px) {
}

/* DESKTOP */
@media (min-width: 1200px) {
	.about-header {
		background: url('../../assets/images/svg/ccwave.svg') center 15%
			no-repeat;
		background-size: 100% auto;
		top: var(--cc-gap);
	}
	.about-header > .coca-container {
		flex-direction: column;
		position: relative;
	}
	.about-header .section-title h1 {
		margin: 64px 0;
	}
	.about-header .section-images {
		margin: 32px calc(0px - var(--cc-gap));
		position: relative;
		width: unset;
		max-width: unset;
	}
	.about-header .section-images img {
		width: 95%;
	}
} /* MOBILE */
@media (max-width: 575.98px) {
}

/* TABLE */
@media (min-width: 576px) and (max-width: 1199.98px) {
}

/* DESKTOP */
@media (min-width: 1200px) {
} /* MOBILE */
@media (max-width: 575.98px) {
	.landing-benned-van-nagy-kep .nagykep {
		border-radius: 100% 0 100% 100%;
		width: 90vw;
		height: auto;
		position: relative;
		right: calc(-20% - 64px);
		top: -1%;
		z-index: 1;
	}
	.landing-benned-van-nagy-kep > .row {
		position: absolute;
		top: 0;
		z-index: 2;
	}
	.landing-benned-van-nagy-kep .register-calendar-svg {
		width: 60vw;
		top: 5%;
		position: relative;
		left: 10%;
	}
	.landing-benned-van-nagy-kep .register-bulb-svg {
		position: relative;
		width: 30vw;
		z-index: 3;
		left: -15%;
		top: -50%;
	}

	.landing-regisztralj {
		margin-bottom: 0;
	}
	.landing-regisztralj .section-title .col-7 {
		width: 90%;
	}
	.landing-regisztralj .section-text .col {
		width: 100%;
	}
	.landing-regisztralj .btn.registration {
		margin: 1.5rem 0;
	}

	.landing-regisztralj .video {
		width: 100%;
		display: flex;
		position: relative;
		flex-direction: column;
		justify-content: center;
		cursor: pointer;
	}
	.landing-regisztralj .video .video-button {
		position: absolute;
		width: 88px;
		height: 88px;
		border-radius: 48px;
		transition: 0.25s all linear;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-self: center;
	}
	.landing-regisztralj .video .video-button svg {
		align-self: center;
	}
	.landing-regisztralj .video .video-thumbnail {
	}
	.landing-regisztralj .video-thumbnail img {
		border-radius: 36px;
		border: 12px solid var(--white);
		box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.08);
		width: 100%;
	}

	.landing-counter-group {
		display: block;
	}
	.landing-counter-group .group-wrap {
		display: block;
		overflow: hidden;
		max-width: calc(100% + 2 * 10px);
		max-height: calc(48vw + 2 * 64px - 20px);
		margin: -64px -10px 32px -10px;
		padding: 0;
	}
	.landing-counter-group .counter-row {
		flex-wrap: nowrap;
		overflow-x: scroll;
		overflow-y: visible;
		padding: 64px 10px;
		margin: 0;
		position: relative;
	}

	.landing-counter-group {
		min-height: unset;
	}
	.landing-counter-group .cnt {
		margin: 0 0 32px 0;
		height: unset;
		justify-content: center;
	}
	.landing-counter-group .splide__track {
		overflow: visible;
	}

	.landing-nezd-meg-mit-tanulhatsz {
		margin-bottom: 32px;
	}
	.landing-nezd-meg-mit-tanulhatsz .tab-list {
		padding: 16px 0;
		margin: 0 -10px;
		margin-bottom: 32px;
		height: 120px;
		overflow: hidden;
	}
	.landing-nezd-meg-mit-tanulhatsz .tab-list-wrapper {
		display: flex;
		flex-wrap: nowrap;
		margin: 0 -10px;
		padding: 48px 10px;
		padding-right: 20%;
		overflow-x: scroll;
	}
	.landing-nezd-meg-mit-tanulhatsz .tab-list-wrapper .tab {
		flex: 0;
		white-space: nowrap;
	}

	.landing-nezd-meg-mit-tanulhatsz-modul-list {
		margin: 32px calc(0px - var(--cc-gap));
	}
	.splide-landing-nezd-meg-mit-tanulhatsz-modul-list {
		padding-bottom: 32px;
	}
	.splide-landing-nezd-meg-mit-tanulhatsz-modul-list .cnt {
		margin: 16px 0 32px 0;
		height: unset;
		justify-content: center;
	}
	.splide-landing-nezd-meg-mit-tanulhatsz-modul-list .splide__track {
		margin-bottom: 16px;
	}
	.landing-counter-group {
		min-height: unset;
	}
	.landing-counter-group .cnt {
		margin: 64px 0 32px 0;
		height: unset;
		justify-content: center;
	}
	.landing-counter-group .splide__track {
		overflow: hidden;
	}

	.landing-quotes {
		margin: 32px 0;
		min-height: unset;
	}
	.splide-landing-quotes-list {
		padding-bottom: 64px;
	}
	.splide-landing-quotes-list .splide__track {
		overflow: visible;
	}

	.landing-kik-vagyunk-mi {
		margin-bottom: 32px;
	}
	.landing-kik-vagyunk-mi .kik-vagyunk {
		margin: 0 -64px;
		display: block;
	}
	.landing-kik-vagyunk-mi .kik-vagyunk-kep img {
		max-width: 100%;
	}
	.landing-kik-vagyunk-mi .kik-vagyunk-card {
		display: block;
		justify-content: center;
		padding: 0;
		margin: 0;
	}
	.landing-kik-vagyunk-mi .kik-vagyunk-card .card-float {
		border-radius: 32px;
		position: relative;
		padding: 32px;
		z-index: 2;
		box-shadow: 0 0 32px rgba(0, 0, 0, 0.2);
		margin: auto;
		margin-top: -12.5%;
		width: calc(100% - 128px);
	}
	.landing-kik-vagyunk-mi .kik-vagyunk-card .btn {
		width: 100%;
	}

	.landing-podcast {
		min-height: unset;
		margin-bottom: 32px;
	}
	.landing-podcast .img-cnt {
		overflow: hidden;
		height: 100%;
		position: relative;
	}
	.landing-podcast .img-cnt > img {
		width: 300%;
		position: relative;
		left: -110%;
	}
	.landing-podcast .float-cnt {
		position: absolute;
		height: 100%;
		z-index: 2;
		flex-direction: column;
		justify-content: center; /*! top: 0; */ /*! margin-top: -50%; */
	}
	.landing-podcast .float-cnt > div {
		width: 100%;
	}

	.landing-podcast .float-cnt h3 {
		font-size: 18px;
	}
	.landing-podcast .float-cnt p {
		margin-top: 4%;
		margin-bottom: 0;
	}
	.landing-podcast .float-cnt img {
		width: 120%;
	}

	.landing-fejleszd-magad {
		margin-bottom: 32px;
	}
	.landing-fejleszd-magad * {
		display: block;
	}
	.landing-fejleszd-magad .col-6 {
		width: 100%;
	}
	.landing-fejleszd-magad img {
		width: 100%;
		right: -20px;
		position: relative;
	}
}

/* TABLE */
@media (min-width: 576px) and (max-width: 1199.98px) {
	.landing-benned-van-nagy-kep .nagykep {
		border-radius: 100% 0 100% 100%;
		width: 90vw;
		height: auto;
		position: relative;
		right: calc(-20% - 64px);
		top: -1%;
		z-index: 1;
	}
	.landing-benned-van-nagy-kep > .row {
		position: absolute;
		top: 0;
		z-index: 2;
	}
	.landing-benned-van-nagy-kep .register-calendar-svg {
		width: 60vw;
		top: 50%;
		position: relative;
		left: 10%;
	}
	.landing-benned-van-nagy-kep .register-bulb-svg {
		position: relative;
		width: 30vw;
		z-index: 3;
		left: -25%;
		top: -10%;
	}

	.landing-regisztralj {
		margin-bottom: 0;
	}
	.landing-regisztralj .section-title .col-7 {
		width: 90%;
	}
	.landing-regisztralj .section-text .col {
		width: 100%;
	}
	.landing-regisztralj .btn.registration {
		margin: 1.5rem 0;
	}

	.landing-regisztralj .video {
		width: 100%;
		display: flex;
		position: relative;
		flex-direction: column;
		justify-content: center;
		cursor: pointer;
	}
	.landing-regisztralj .video .video-button {
		position: absolute;
		width: 88px;
		height: 88px;
		border-radius: 48px;
		transition: 0.25s all linear;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-self: center;
	}
	.landing-regisztralj .video .video-button svg {
		align-self: center;
	}
	.landing-regisztralj .video .video-thumbnail {
		display: inline-block;
		align-self: center;
	}
	.landing-regisztralj .video-thumbnail img {
		border-radius: 36px;
		border: 12px solid var(--white);
		box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.08);
		width: 100%;
	}

	.landing-counter-group {
		min-height: unset;
	}
	.landing-counter-group .cnt {
		margin: 64px 0 32px 0;
		height: unset;
		justify-content: center;
	}
	.landing-counter-group .splide__track {
		overflow: visible;
	}

	.landing-nezd-meg-mit-tanulhatsz {
		margin-bottom: 64px;
	}
	.landing-nezd-meg-mit-tanulhatsz .tab-list {
		padding: 16px 64px;
		margin: 0 -64px;
		margin-bottom: 16px;
		height: 110px;
		overflow: hidden;
	}
	.landing-nezd-meg-mit-tanulhatsz .tab-list-wrapper {
		display: flex;
		flex-wrap: nowrap;
		padding: 32px 72px;
		padding-right: 20%;
		overflow-x: scroll;
		margin: 0 -64px;
	}
	.landing-nezd-meg-mit-tanulhatsz .tab-list-wrapper .tab {
		flex: 0;
		white-space: nowrap;
	}

	.landing-nezd-meg-mit-tanulhatsz-modul-list {
		margin: 32px calc(0px - var(--cc-gap));
	}
	.splide-landing-nezd-meg-mit-tanulhatsz-modul-list {
		padding-bottom: 32px;
	}
	.splide-landing-nezd-meg-mit-tanulhatsz-modul-list .cnt {
		margin: 16px 0 32px 0;
		height: unset;
		justify-content: center;
	}
	.splide-landing-nezd-meg-mit-tanulhatsz-modul-list .splide__track {
		margin-bottom: 16px;
	}

	.landing-quotes {
		margin: 32px 0;
	}
	.splide-landing-quotes-list {
		padding-bottom: 64px;
	}
	.splide-landing-quotes-list .splide__track {
		overflow: visible;
	}

	.landing-kik-vagyunk-mi {
		margin-bottom: 64px;
	}
	.landing-kik-vagyunk-mi .kik-vagyunk {
		margin: 0 -64px;
		display: block;
	}
	.landing-kik-vagyunk-mi .kik-vagyunk-kep img {
		max-width: 100%;
	}
	.landing-kik-vagyunk-mi .kik-vagyunk-card {
		display: block;
		justify-content: center;
		padding: 0;
		margin: 0;
	}
	.landing-kik-vagyunk-mi .kik-vagyunk-card .card-float {
		border-radius: 32px;
		position: relative;
		padding: 32px;
		z-index: 2;
		box-shadow: 0 0 32px rgba(0, 0, 0, 0.2);
		margin: auto;
		margin-top: -12.5%;
		width: calc(100% - 128px);
	}
	.landing-kik-vagyunk-mi .kik-vagyunk-card .btn {
		width: 100%;
	}

	.landing-podcast {
		min-height: unset;
		margin-bottom: 64px;
	}
	.landing-podcast .img-cnt {
		overflow: hidden;
		height: 100%;
		position: relative;
	}
	.landing-podcast .img-cnt > img {
		width: 200%;
		position: relative;
		left: -60%;
	}
	.landing-podcast .float-cnt {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 2;
		flex-direction: column;
		justify-content: center; /*! top: 0; */ /*! margin-top: -50%; */
	}
	.landing-podcast .float-cnt > div {
		width: 80%;
	}

	.landing-podcast .float-cnt p {
		margin-top: 10%;
		margin-bottom: 0;
	}
	.landing-podcast .float-cnt img {
		width: 100%;
	}

	.landing-fejleszd-magad {
		margin-bottom: 64px;
	}
	.landing-fejleszd-magad * {
		display: block;
	}
	.landing-fejleszd-magad .col-6 {
		width: 100%;
	}
	.landing-fejleszd-magad img {
		width: 100%;
		right: -64px;
		position: relative;
	}
}

/* DESKTOP */
@media (min-width: 1200px) {
	.landing-benned-van-nagy-kep {
		position: absolute;
		z-index: -1;
		width: 100%;
		height: calc(100vh - var(--cc-gap));
	}
	.landing-benned-van-nagy-kep * {
		height: 100%;
		position: relative;
	}
	.landing-benned-van-nagy-kep .nagykep {
		border-radius: 100% 0 100% 100%;
		width: 53vw;
		height: auto;
		position: absolute;
		right: -180px;
		top: 0px;
		max-width: 800px;
	}
	.landing-benned-van-nagy-kep .register-calendar-svg {
		width: 256px;
		height: 256px;
		position: absolute;
		top: 1%;
		margin-left: -40%;
	}
	.landing-benned-van-nagy-kep .register-bulb-svg {
		width: 208px;
		height: 208px;
		position: absolute;
		top: -3%;
		margin-left: 5%;
	}

	.landing-regisztralj {
		top: var(--cc-gap);
	}

	.landing-regisztralj .video {
		display: flex;
		position: relative;
		flex-direction: column;
		justify-content: center;
		cursor: pointer;
	}
	.landing-regisztralj .video .video-button {
		position: absolute;
		width: 88px;
		height: 88px;
		border-radius: 48px;
		transition: 0.25s all linear;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-self: center;
	}
	.landing-regisztralj .video .video-button svg {
		align-self: center;
	}
	.landing-regisztralj .video .video-thumbnail {
		display: inline-block;
		align-self: center;
		max-width: 30vw;
	}
	.landing-regisztralj .video .video-thumbnail img {
		border-radius: 64px;
		border: 18px solid var(--white);
		box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.08);
		max-width: 100%;
	}
	.landing-regisztralj .video:hover .video-button {
		transform: translateY(-10%);
	}
	.landing-regisztralj .btn.registration {
		margin: 4em 0;
	}

	.mentors-counter-list .cnt {
		margin: 64px 0 32px 0;
		height: unset;
		justify-content: center;
	}
	.mentors-counter-list .splide__track {
		overflow: hidden;
	}

	.landing-nezd-meg-mit-tanulhatsz {
		margin-bottom: 32px;
	}
	.landing-nezd-meg-mit-tanulhatsz .tab-list {
		padding-top: 32px;
		display: flex;
		justify-content: center;
	}
	.landing-nezd-meg-mit-tanulhatsz .tab-list-wrapper {
		padding: 32px 0;
	}

	.landing-nezd-meg-mit-tanulhatsz-modul-list {
		margin: 32px calc(0px - var(--cc-gap));
		margin-top: 0;
	}
	.splide-landing-nezd-meg-mit-tanulhatsz-modul-list {
		padding-bottom: 32px;
	}
	.splide-landing-nezd-meg-mit-tanulhatsz-modul-list .cnt {
		margin: 16px 0 32px 0;
		height: unset;
		justify-content: center;
	}
	.splide-landing-nezd-meg-mit-tanulhatsz-modul-list .splide__track {
		margin-bottom: 16px;
	}

	.landing-quotes {
		margin: 32px 0;
	}
	.splide-landing-quotes-list {
		padding-bottom: 64px;
	}
	.splide-landing-quotes-list .splide__track {
		overflow: visible;
	}

	.landing-kik-vagyunk-mi {
		min-height: auto;
		padding: 32px 0;
	}
	.landing-kik-vagyunk-mi .coca-container {
		padding-left: 0;
	}
	.landing-kik-vagyunk-mi .kik-vagyunk-kep img {
		max-width: 100%;
		border-radius: 0 32px 32px 0;
	}
	.landing-kik-vagyunk-mi .kik-vagyunk-card {
		display: flex;
		justify-content: center;
	}
	.landing-kik-vagyunk-mi .kik-vagyunk-card .card-float {
		border-radius: 32px;
		position: relative;
		padding: 32px;
		z-index: 2;
		box-shadow: 0 0 32px rgba(0, 0, 0, 0.2);
		align-self: center;
		margin-left: -112px;
	}

	.landing-quotes {
		min-height: unset;
	}

	.landing-podcast {
		min-height: unset;
	}
	.landing-podcast .img-cnt > img {
		width: 100%;
		position: relative;
		border-radius: 0%;
	}
	.landing-podcast .float-cnt {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 2;
		flex-direction: column;
		justify-content: center;
	}

	.landing-podcast .float-cnt p {
		margin-top: 5%;
		margin-bottom: 0;
	}
	.landing-podcast .float-cnt img {
		width: 100%;
	}

	.landing-fejleszd-magad {
		margin-bottom: -30px;
	}
	.landing-fejleszd-magad .coca-container {
		padding-right: 0;
	}
	.landing-fejleszd-magad .text {
		align-self: center;
	}
	.landing-fejleszd-magad img {
		width: 100%;
	}

	.landing-quotes {
		min-height: auto;
	}
}
.btn.cc-navigation-menu-button:hover {
	color: var(--red);
}
.cc-navigation .login span[class^='ejicon-'] {
	display: inline-block;
	margin-right: 10px;
}

.cc-navigation-toggle {
	width: 100%;
	margin: auto;
	position: fixed;
	z-index: 10;
	transition: all 0.5s;
}

/* MOBILE */
@media (max-width: 575.98px) {
	.cc-navigation {
		position: absolute;
		z-index: 10;
		width: 100%;
		height: 80px;
	}
	.cc-navigation-toggle-hide-closed {
		top: 0px;
	}
	.cc-navigation-toggle-hide-open {
		top: 80px;
	}
	.cc-navigation-toggle-space {
		border-radius: 0 0 10px 10px;
		text-align: center;
		margin: auto;
		padding: 2px 0;
		width: 56px;
		background-color: white;
	}

	.cc-navigation .cc-logo,
	.cc-navigation .cc-menu-end {
		height: 48px;
		padding: 16px 0;
		box-sizing: content-box;
	}

	.cc-navigation .cc-logo {
		width: 148px;
	}

	.cc-navigation .cc-menu-end {
		flex: 0;
		min-width: 144px;
	}
	.cc-navigation .logo {
		position: relative;
		display: block;
		height: 48px;
		padding-top: 16px;
		overflow: hidden;
		width: 148px;
		transition: var(--trans-normal);
	}

	.cc-navigation .logo:hover {
		transform: translateY(-8px);
	}

	.cc-navigation-menu-link {
		font-weight: 600;
		display: block;
		padding: 40px 8px;
		overflow: hidden;
		position: relative;
		transition: var(--trans-normal);
		color: var(--dark);
		width: fit-content;
	}

	.cc-navigation-menu-link::before {
		content: '';
		display: block;
		position: absolute;
		border-radius: 4px;
		top: calc(100% - 4px);
		width: calc(100% - 32px);
		height: 32px;
		background: var(--white);
		transition: var(--trans-normal);
		transform: scaleX(0);
		transform-origin: center;
	}

	.cc-navigation-menu-link:hover {
		color: var(--white);
	}

	.cc-navigation-menu-link:hover::before {
		transform: scaleX(1);
	}

	.cc-navigation-menu-button:hover {
		color: var(--red);
	}

	.cc-menu {
		position: fixed;
	}

	.cc-navigation .login {
		width: 48px;
		height: 48px;
		padding: 0;
		text-align: center;
	}
	.cc-navigation .login .button-text {
		display: none;
	}
	.cc-navigation .login span[class^='ejicon-'] {
		margin: 0;
	}
	.cc-navigation .login span[class^='ejicon-']::before {
		font-size: 16px;
	}

	.cc-menu-end .cc-navigation-menu-button {
		margin-left: 16px;
	}
	.cc-menu .cc-navigation-menu-button {
		position: absolute;
		top: 16px;
		right: 16px;
	}
	.cc-menu .cc-navigation-menu-button:hover * {
		color: var(--red);
	}

	.cc-navigation.scrolled {
		position: fixed;
	}
	.cc-navigation.scrolled .buttons {
		background: var(--white);
		filter: drop-shadow(0 16px 8px rgba(0, 0, 0, 0.08));
	}

	.cc-navigation .spacer {
		z-index: 50;
	}
	.cc-navigation .spacer {
		position: fixed;
		left: -100%;
		height: 100%;
		width: 100%;
	}
	.cc-navigation .spacer > .row {
		height: 100%;
		width: 288px;
		border-radius: 0 48px 48px 0;
		filter: drop-shadow(0 0 16px rgba(0, 0, 0, 0.5));
		background: var(--red);
	}
	.cc-navigation .spacer > .row > .cc-menu {
		position: relative;
		height: 100%;
	}
	.cc-navigation .spacer > .row {
	}
	.cc-navigation .spacer .cc-logo,
	.cc-navigation .spacer .cc-menu-end {
		display: none;
	}
	.cc-navigation .spacer .cc-navigation-menu {
		justify-content: start;
		display: inline-flex;
		flex-direction: column;
		padding: 0;
		max-width: 80%;
		margin: auto;
		max-height: calc(100% - 2 * 46px);
	}
	.cc-navigation .spacer .cc-navigation-menu a:not(.dropdown-item) {
		color: var(--white);
		font-size: 18px;
		padding: 1rem 1rem;
		display: block;
	}

	.cc-navigation .spacer .cc-logo {
		min-width: 148px;
		flex: 0;
	}
	.cc-navigation .spacer .cc-menu {
		pointer-events: all;
		display: flex;
	}

	.show-navigation-menu {
		left: 220px;
		overflow: hidden;
		max-height: 100%;
		max-width: 100%;
		position: fixed;
	}
	.show-navigation-menu .cc-navigation-menu {
		left: 0;
	}
	.show-navigation-menu .cc-navigation .buttons {
		filter: blur(10px);
	}
	.show-navigation-menu .cc-content {
		filter: blur(10px);
		pointer-events: none;
	}
	.show-navigation-menu .cc-navigation .spacer {
		left: 0;
		padding: 0;
	}
	.show-navigation-menu .cc-navigation.scrolled .buttons {
		background: var(--white);
		filter: drop-shadow(16px 16px 8px rgba(0, 0, 0, 0.08)) blur(10px);
	}
	.show-navigation-menu .cc-footer {
		filter: drop-shadow(16px 16px 8px rgba(0, 0, 0, 0.08)) blur(10px);
	}
}

/* TABLE */
@media (min-width: 576px) and (max-width: 1199.98px) {
	.cc-navigation {
		position: absolute;
		z-index: 10;
		width: 100%;
		height: 80px;
	}
	.cc-navigation-toggle-hide-closed {
		top: 0px;
	}
	.cc-navigation-toggle-hide-open {
		top: 80px;
	}
	.cc-navigation-toggle-space {
		border-radius: 0 0 10px 10px;
		text-align: center;
		margin: auto;
		padding: 8px 0;
		width: 84px;
		background-color: white;
	}

	.cc-navigation .cc-logo,
	.cc-navigation .cc-menu-end {
		height: 48px;
		padding: 16px 0;
		box-sizing: content-box;
	}

	.cc-navigation .cc-logo {
		width: 148px;
	}

	.cc-navigation .cc-menu-end {
		flex: 0;
		min-width: 144px;
	}
	.cc-navigation .logo {
		position: relative;
		display: block;
		height: 48px;
		padding-top: 16px;
		overflow: hidden;
		width: 148px;
		transition: var(--trans-normal);
	}

	.cc-navigation .logo:hover {
		transform: translateY(-8px);
	}

	.cc-navigation-menu-button:hover {
		color: var(--red);
	}

	.cc-menu {
		position: fixed;
	}

	.cc-navigation .login {
		width: 48px;
		height: 48px;
		padding: 0;
		text-align: center;
	}
	.cc-navigation .login .button-text {
		display: none;
	}
	.cc-navigation .login span[class^='ejicon-'] {
		margin: 0;
	}
	.cc-navigation .login span[class^='ejicon-']::before {
		font-size: 16px;
	}

	.cc-menu-end .cc-navigation-menu-button {
		margin-left: 16px;
	}
	.cc-menu .cc-navigation-menu-button {
		position: absolute;
		top: 16px;
		right: 16px;
	}
	.cc-menu .cc-navigation-menu-button:hover * {
		color: var(--red);
	}

	.cc-navigation.scrolled {
		position: fixed;
	}
	.cc-navigation.scrolled .buttons {
		background: var(--white);
		filter: drop-shadow(0 16px 8px rgba(0, 0, 0, 0.08));
	}

	.cc-navigation-menu-link {
		font-weight: 600;
		display: block;
		padding: 40px 8px;
		overflow: hidden;
		position: relative;
		transition: var(--trans-normal);
		color: var(--dark);
		width: fit-content;
	}

	.cc-navigation-menu-link::before {
		content: '';
		display: block;
		position: absolute;
		border-radius: 4px;
		top: calc(100% - 4px);
		width: calc(100% - 32px);
		height: 32px;
		background: var(--white);
		transition: var(--trans-normal);
		transform: scaleX(0);
		transform-origin: center;
	}

	.cc-navigation-menu-link:hover {
		color: var(--white);
	}

	.cc-navigation-menu-link:hover::before {
		transform: scaleX(1);
	}

	.cc-navigation .spacer {
		z-index: 50;
	}
	.cc-navigation .spacer {
		position: fixed;
		left: -100%;
		height: 100%;
		width: 100%;
	}
	.cc-navigation .spacer > .row {
		height: 100%;
		width: 288px;
		border-radius: 0 48px 48px 0;
		filter: drop-shadow(0 0 16px rgba(0, 0, 0, 0.5));
		background: var(--red);
	}
	.cc-navigation .spacer > .row > .cc-menu {
		position: relative;
		height: 100%;
	}
	.cc-navigation .spacer > .row {
	}
	.cc-navigation .spacer .cc-logo,
	.cc-navigation .spacer .cc-menu-end {
		display: none;
	}
	.cc-navigation .spacer .cc-navigation-menu {
		justify-content: start;
		display: inline-flex;
		flex-direction: column;
		padding: 0;
		max-width: 80%;
		margin: auto;
		max-height: calc(100% - 2 * 46px);
	}
	.cc-navigation .spacer .cc-navigation-menu a {
		color: var(--white);
		font-size: 18px;
		padding: 1rem 1rem;
		display: block;
	}

	.cc-navigation .spacer .cc-logo {
		min-width: 148px;
		flex: 0;
	}
	.cc-navigation .spacer .cc-menu {
		pointer-events: all;
		display: flex;
	}

	.show-navigation-menu {
		left: 220px;
		overflow: hidden;
		max-height: 100%;
		max-width: 100%;
		position: fixed;
	}
	.show-navigation-menu .cc-navigation-menu {
		left: 0;
	}
	.show-navigation-menu .cc-navigation .buttons {
		filter: blur(10px);
	}
	.show-navigation-menu .cc-content {
		filter: blur(10px);
		pointer-events: none;
	}
	.show-navigation-menu .cc-navigation .spacer {
		left: 0;
		padding: 0;
	}
	.show-navigation-menu .cc-navigation.scrolled .buttons {
		background: var(--white);
		filter: drop-shadow(16px 16px 8px rgba(0, 0, 0, 0.08)) blur(10px);
	}
	.show-navigation-menu .cc-footer {
		filter: drop-shadow(16px 16px 8px rgba(0, 0, 0, 0.08)) blur(10px);
	}
}

/* DESKTOP */
@media (min-width: 1200px) {
	.mobile-only {
		display: none;
	}
	.cc-navigation {
		position: absolute;
		z-index: 10;
		width: 100%;
		height: 104px;
		border-bottom: 1px solid var(--dark-005);
	}
	.cc-navigation-toggle-hide-closed {
		top: 0px;
	}
	.cc-navigation-toggle-hide-open {
		top: 104px;
	}
	.cc-navigation-toggle-space {
		border-radius: 0 0 10px 10px;
		text-align: center;
		margin: auto;
		padding: 8px 0;
		width: 84px;
		background-color: white;
	}

	.cc-navigation .coca-container,
	.cc-navigation .row,
	.cc-navigation .col.cc-logo {
		position: relative;
		height: 100%;
	}

	.cc-navigation .logo {
		position: relative;
		height: 100%;
		transition: var(--trans-normal);
	}
	.cc-navigation .logo:hover {
		transform: translateY(-8px);
	}
	.cc-navigation .logo img {
		width: 148px;
		height: 31px;
	}
	.cc-navigation .col.cc-menu {
		max-height: 100%;
	}

	.cc-navigation-menu {
		margin: 0;
		display: block;
	}
	.cc-navigation-menu-item {
		margin: 0;
		display: block;
	}
	.cc-navigation-menu-link {
		font-weight: 600;
		display: block;
		padding: 40px 8px;
		overflow: hidden;
		position: relative;
		transition: var(--trans-normal);
		color: var(--dark);
	}

	.cc-navigation-menu-link::before {
		content: '';
		display: block;
		position: absolute;
		border-radius: 4px;
		top: calc(100% - 4px);
		width: calc(100% - 16px);
		height: 32px;
		background: var(--red);
		transition: var(--trans-normal);
		transform: scaleX(0);
		transform-origin: center;
	}

	.cc-navigation-menu-link:hover {
		color: var(--red);
	}

	.cc-navigation-menu-link:hover::before {
		transform: scaleX(1);
	}

	.cc-navigation .col.cc-menu-end {
		max-height: 100%;
	}

	.cc-navigation-menu-button {
		display: none;
	}
	.cc-navigation .login span[class^='ejicon-'] {
		display: none;
	}

	.cc-navigation-menu-button {
		display: none !important;
	}

	.cc-navigation.scrolled {
		position: fixed;
	}
	.cc-navigation.scrolled .buttons {
		background: var(--white);
		filter: drop-shadow(0 16px 8px rgba(0, 0, 0, 0.08));
	}

	.cc-navigation .spacer {
		position: absolute;
		width: calc(100% - 112px);
		z-index: 2;
		pointer-events: none;
	}
	.cc-navigation .spacer .cc-logo {
		min-width: 148px;
		flex: 0;
	}
	.cc-navigation .spacer .cc-menu {
		pointer-events: all;
	}
}
.splide {
	position: relative;
}
.splide__list {
	position: relative;
	display: flex;
}
.cnt {
	display: flex;
	height: 100%;
	align-content: stretch;
}

.splide__arrow:disabled {
	visibility: hidden;
}
.splide__arrow {
	background: var(--red);
	fill: white;
	width: 48px;
	height: 48px;
	border-radius: 40px;
	overflow: hidden;
	opacity: 1;
	top: calc(100% - 24px);
	z-index: 5;
}
.splide__arrow {
	opacity: 1;
	transition: 0.25s all linear;
}
.splide__arrow:hover {
	opacity: 1;
}
/*.splide__arrow.disabled{display:none}*/

.splide__slide--clone {
	visibility: hidden;
}

.splide__pagination button {
	background: var(--gray);
}
.splide__pagination button.is-active {
	background: var(--red);
}
:root {
	/* SCREEN DIMENSION - this updated via javascript*/
	--screenWidth: 100vw;
	--screenHeight: 100vh;

	/* HEXA COLOR VALUES */
	--black: #000;
	--white: #fff;

	--red-fade: #fee6e6;

	--green: #13c767;
	--green-fade: #e7f9f0;

	--yellow: #f5dd00;
	--yellow-fade: #fef7d5;

	--orange: #ff9901;
	--orange-fade: #fff5e6;

	--purple: #933cea;
	--purple-fade: #f5ecfd;

	--blue: #0070f4;
	--blue-fade: #e6f1fe;

	--light-blue: #01c2ff;
	--light-blue-fade: #e6f9ff;

	--magenta: #ea50d1;
	--magenta-fade: #ffe6fa;

	--gray: #8e8f8f;

	--dark: #12072a;

	--dark-gray: #646568;
	--medium-gray: #a9a8ae;
	--light-gray: #d4d6db;

	--dirt: #efeff0;
	--medium-dirt: #f5f6f7;
	--bright-dirt: #fafafa;

	/* RGBAS */
	--dark-005: rgba(18, 7, 42, 0.05);

	/* TRANSITIONS */
	--trans-normal: 0.25s all linear;
	--trans-medium: 0.5s all linear;

	/* EFFECTS */
	--shadow-red: 8px 8px 24px rgba(244, 0, 0, 0.25);
	--shadow-light: 16px 16px 16px rgba(0, 0, 0, 0.08);
	--shadow-light-big: 16px 16px 60px rgba(0, 0, 0, 0.08);
}

/* MOBILE - xs */
@media (max-width: 575.98px) {
	:root {
		--cc-gap: 10px;
	}
}

/* TABLE  xs - xl */
@media (min-width: 576px) and (max-width: 1199.98px) {
	:root {
		--cc-gap: 32px;
	}
}

/* DESKTOP  - xl */
@media (min-width: 1200px) {
	:root {
		--cc-gap: 112px;
	}
} /* MOBILE */
@media (max-width: 575.98px) {
	.cc-counter {
		transition: 0.25s all linear;
		border-radius: 36px;
	}
	.cc-counter {
		width: 100%;
		text-align: center;
		cursor: pointer;
	}
	.cc-counter .counter-wrap {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.cc-counter .icon {
		position: relative;
		display: block;
		min-height: 48px;
		width: 48px;
		border-radius: 32px;
		flex: 0;
		margin: auto;
		margin-top: 36px;
		margin-bottom: 8px;
	}
	.cc-counter .icon {
		position: relative;
		display: block;
		height: 48px;
		width: 48px;
		border-radius: 32px;
		flex: 0;
		margin: auto;
		margin-top: 36px;
		margin-bottom: 8px;
	}
	.cc-counter .icon::before {
		position: relative;
		top: 32%;
	}
	.cc-counter h2 {
		margin-bottom: 0px;
	}
	.cc-counter p {
		margin-bottom: 32px;
		font-size: 12px;
	}
	.cc-counter:hover {
		box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.08);
		background: #fff;
		transform: translateY(-10%);
	}
}

/* TABLE */
@media (min-width: 576px) and (max-width: 1199.98px) {
	.cc-counter {
		transition: 0.25s all linear;
		border-radius: 36px;
	}
	.cc-counter {
		width: 70%;
		min-width: 170px;
		text-align: center;
		cursor: pointer;
	}
	.cc-counter .counter-wrap {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.cc-counter .icon {
		position: relative;
		display: block;
		min-height: 48px;
		width: 48px;
		border-radius: 32px;
		flex: 0;
		margin: auto;
		margin-top: 36px;
		margin-bottom: 8px;
	}
	.cc-counter .icon {
		position: relative;
		display: block;
		height: 48px;
		width: 48px;
		border-radius: 32px;
		flex: 0;
		margin: auto;
		margin-top: 36px;
		margin-bottom: 8px;
	}
	.cc-counter .icon::before {
		position: relative;
		top: 32%;
	}
	.cc-counter h2 {
		margin-bottom: 0px;
	}
	.cc-counter p {
		margin-bottom: 32px;
		font-size: 12px;
	}
	.cc-counter:hover {
		box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.08);
		background: #fff;
		transform: translateY(-10%);
	}
}

/* DESKTOP */
@media (min-width: 1200px) {
	.cc-counter {
		transition: 0.25s all linear;
		border-radius: 36px;
	}
	.cc-counter {
		width: 70%;
		min-width: 170px;
		text-align: center;
		cursor: pointer;
	}
	.cc-counter .counter-wrap {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.cc-counter .icon {
		position: relative;
		display: block;
		min-height: 48px;
		width: 48px;
		border-radius: 32px;
		flex: 0;
		margin: auto;
		margin-top: 36px;
		margin-bottom: 8px;
	}
	.cc-counter .icon {
		position: relative;
		display: block;
		height: 48px;
		width: 48px;
		border-radius: 32px;
		flex: 0;
		margin: auto;
		margin-top: 36px;
		margin-bottom: 8px;
	}
	.cc-counter .icon::before {
		position: relative;
		top: 32%;
	}
	.cc-counter h2 {
		margin-bottom: 0px;
	}
	.cc-counter p {
		margin-bottom: 32px;
		font-size: 12px;
	}
	.cc-counter:hover {
		box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.08);
		background: #fff;
		transform: translateY(-10%);
	}
}
.cc-footer {
	margin-top: 0;
	width: 100%;
	padding-top: 0;
	position: relative;
	z-index: 1;
	min-height: unset;
	margin-top: -10%;
}
.cc-footer .coca-container {
	background-color: var(--medium-dirt);
	padding-top: 64px;
	padding-bottom: 80px;
}
.footer-top {
	width: calc(100% + 2px);
	margin: -1px;
	margin-top: 0;
}
.cc-footer .logo img {
	width: 245px;
}

.cc-footer .icons [class^='ejicon-']::before {
	color: var(--white);
}
.cc-footer a.btn {
	margin-right: 16px;
	margin-bottom: 20px;
}

/* MOBILE */
@media (max-width: 575.98px) {
	.cc-footer > .coca-container > .row {
		flex-direction: column;
	}
	.cc-footer > .coca-container > .row {
		flex-direction: column;
		align-items: center;
	}
	.cc-footer > .coca-container > .row > * {
		width: 320px;
	}
}

/* TABLE */
@media (min-width: 576px) and (max-width: 763.98px) {
	.cc-footer > .coca-container > .row {
		flex-direction: column;
		align-items: center;
	}
	.cc-footer > .coca-container > .row > * {
		min-width: 320px;
		max-width: 320px;
	}
}

@media (min-width: 764px) and (max-width: 1199.98px) {
	.cc-footer > .coca-container > .row {
		justify-content: space-between;
	}
	.cc-footer > .coca-container > .row > * {
		width: 310px;
	}

	.cc-footer .hbc.col {
		min-width: 310px;
		max-width: 310px;
	}
	.cc-footer .right.col {
		min-width: 310px;
		max-width: 310px;
	}
}

/* DESKTOP */
@media (min-width: 1200px) {
	.cc-footer > .coca-container > .row {
		justify-content: space-between;
	}
	.cc-footer .right.col {
		min-width: 480px;
		max-width: 480px;
	}
}

/* MOBILE */
@media (max-width: 575.98px) {
	.cc-quote {
		margin: 0 10px;
		border-radius: 36px;
		border: 1px solid var(--dirt);
		padding: 32px 40px;
		padding-top: 80px;
		background: var(--white) var(--cc-qoute-img) no-repeat 32px 32px;
		background-color: var(--white);
		box-shadow: var(--shadow-light);
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.cc-quote .quotes-text {
		color: var(--black);
		padding: 16px 0;
	}
	.cc-quote .mentor-name {
		margin-bottom: 4px;
	}
	.cc-quote .course-name {
		font-size: 14px;
		margin-bottom: 0px;
	}
	.cc-quote .mentor-profile-pic {
		min-width: 64px;
		max-width: 64px;
	}
	.cc-quote .mentor-profile-pic-wrapper {
		border-radius: 64px;
		overflow: hidden;
		color: var(--white);
		position: relative;
		z-index: 1;
	}
	.cc-quote .mentor-profile-pic-wrapper::after {
		position: absolute;
		z-index: -1;
		content: '\e94a';
		font-size: 16px;
		font-family: 'ejicon';
		display: inline-block;
		top: -50%;
		left: -50%;
		width: 64px;
		height: 64px;
		border: 1px solid var(--dirt);
		border-radius: 64px;
		background: var(--dirt);
		padding: 26px 32px;
	}
}

/* TABLE */
@media (min-width: 576px) and (max-width: 1199.98px) {
	.cc-quote {
		margin: 0 10px;
		border-radius: 36px;
		border: 1px solid var(--dirt);
		padding: 32px 40px;
		padding-top: 80px;
		background: var(--white) var(--cc-qoute-img) no-repeat 32px 32px;
		background-color: var(--white);
		box-shadow: var(--shadow-light);
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.cc-quote .quotes-text {
		color: var(--black);
		padding: 16px 0;
	}
	.cc-quote .mentor-name {
		margin-bottom: 4px;
	}
	.cc-quote .course-name {
		font-size: 14px;
		margin-bottom: 0px;
	}
	.cc-quote .mentor-profile-pic {
		min-width: 64px;
		max-width: 64px;
	}
	.cc-quote .mentor-profile-pic-wrapper {
		border-radius: 64px;
		overflow: hidden;
		color: var(--white);
		position: relative;
		z-index: 1;
	}
	.cc-quote .mentor-profile-pic-wrapper::after {
		position: absolute;
		z-index: -1;
		content: '\e94a';
		font-size: 16px;
		font-family: 'ejicon';
		display: inline-block;
		top: -50%;
		left: -50%;
		width: 64px;
		height: 64px;
		border: 1px solid var(--dirt);
		border-radius: 64px;
		background: var(--dirt);
		padding: 26px 32px;
	}
}

/* DESKTOP */
@media (min-width: 1200px) {
	.cc-quote {
		margin: 0 10px;
		border-radius: 36px;
		border: 1px solid var(--dirt);
		padding: 32px 40px;
		padding-top: 80px;
		background: var(--white) var(--cc-qoute-img) no-repeat 32px 32px;
		background-color: var(--white);
		box-shadow: var(--shadow-light);
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.cc-quote .quotes-text {
		color: var(--black);
		padding: 16px 0;
	}
	.cc-quote .mentor-name {
		margin-bottom: 4px;
	}
	.cc-quote .course-name {
		font-size: 14px;
		margin-bottom: 0px;
	}
	.cc-quote .mentor-profile-pic {
		min-width: 64px;
		max-width: 64px;
	}
	.cc-quote .mentor-profile-pic-wrapper {
		border-radius: 64px;
		overflow: hidden;
		color: var(--white);
		position: relative;
		z-index: 1;
	}
	.cc-quote .mentor-profile-pic-wrapper::after {
		position: absolute;
		z-index: -1;
		content: '\e94a';
		font-size: 16px;
		font-family: 'ejicon';
		display: inline-block;
		top: -50%;
		left: -50%;
		width: 64px;
		height: 64px;
		border: 1px solid var(--dirt);
		border-radius: 64px;
		background: var(--dirt);
		padding: 26px 32px;
	}
} /*
	BACKGROUND COLORS
*/

.bg-black {
	background-color: var(--black);
}
.bg-white {
	background-color: var(--white);
}

.bg-red {
	background-color: var(--red);
}
.bg-red-fade {
	background-color: var(--red-fade);
}

.bg-green {
	background-color: var(--green);
}
.bg-green-fade {
	background-color: var(--green-fade);
}

.bg-yellow {
	background-color: var(--yellow);
}
.bg-yellow-fade {
	background-color: var(--yellow-fade);
}

.bg-orange {
	background-color: var(--orange);
}
.bg-orange-fade {
	background-color: var(--orange-fade);
}

.bg-purple {
	background-color: var(--purple);
}
.bg-purple-fade {
	background-color: var(--purple-fade);
}

.bg-blue {
	background-color: var(--blue);
}
.bg-blue-fade {
	background-color: var(--blue-fade);
}

.bg-light-blue {
	background-color: var(--light-blue);
}
.bg-light-blue-fade {
	background-color: var(--light-blue-fade);
}

.bg-magenta {
	background-color: var(--magenta);
}
.bg-magenta-fade {
	background-color: var(--magenta-fade);
}

.bg-gray {
	background-color: var(--gray);
}
.bg-dark {
	background-color: var(--dark);
}

.bg-dark-gray {
	background-color: var(--dark-gray);
}
.bg-medium-gray {
	background-color: var(--medium-dark);
}
.bg-light-gray {
	background-color: var(--light-dark);
}

.bg-dirt {
	background-color: var(--dirt);
}
.bg-medium-dirt {
	background-color: var(--medium-dark);
}
.bg-bright-dirt {
	background-color: var(--bright-dark);
}

/*
	TEXT COLORS
*/

.cl-black {
	color: var(--black);
}
.cl-white {
	color: var(--white);
}

.cl-red {
	color: var(--red);
}
.cl-red-fade {
	color: var(--red-fade);
}

.cl-green {
	color: var(--green);
}
.cl-green-fade {
	color: var(--green-fade);
}

.cl-yellow {
	color: var(--yellow);
}
.cl-yellow-fade {
	color: var(--yellow-fade);
}

.cl-orange {
	color: var(--orange);
}
.cl-orange-fade {
	color: var(--orange-fade);
}

.cl-purple {
	color: var(--purple);
}
.cl-purple-fade {
	color: var(--purple-fade);
}

.cl-blue {
	color: var(--blue);
}
.cl-blue-fade {
	color: var(--blue-fade);
}

.cl-light-blue {
	color: var(--light-blue);
}
.cl-light-blue-fade {
	color: var(--light-blue-fade);
}

.cl-magenta {
	color: var(--magenta);
}
.cl-magenta-fade {
	color: var(--magenta-fade);
}

.cl-gray {
	color: var(--gray);
}
.cl-dark {
	color: var(--dark);
}

.cl-dark-gray {
	color: var(--dark-gray);
}
.cl-medium-gray {
	color: var(--medium-dark);
}
.cl-light-gray {
	color: var(--light-dark);
}

.cl-dirt {
	color: var(--dirt);
}
.cl-medium-dirt {
	color: var(--medium-dark);
}
.cl-bright-dirt {
	color: var(--bright-dark);
}

/*
	BORDER COLORS
*/

.br-black {
	border-color: var(--black);
}
.br-white {
	border-color: var(--white);
}

.br-red {
	border-color: var(--red);
}
.br-red-fade {
	border-color: var(--red-fade);
}

.br-green {
	border-color: var(--green);
}
.br-green-fade {
	border-color: var(--green-fade);
}

.br-yellow {
	border-color: var(--yellow);
}
.br-yellow-fade {
	border-color: var(--yellow-fade);
}

.br-orange {
	border-color: var(--orange);
}
.br-orange-fade {
	border-color: var(--orange-fade);
}

.br-purple {
	border-color: var(--purple);
}
.br-purple-fade {
	border-color: var(--purple-fade);
}

.br-blue {
	border-color: var(--blue);
}
.br-blue-fade {
	border-color: var(--blue-fade);
}

.br-light-blue {
	border-color: var(--light-blue);
}
.br-light-blue-fade {
	border-color: var(--light-blue-fade);
}

.br-magenta {
	border-color: var(--magenta);
}
.br-magenta-fade {
	border-color: var(--magenta-fade);
}

.br-gray {
	border-color: var(--gray);
}
.br-dark {
	border-color: var(--dark);
}

.br-dark-gray {
	border-color: var(--dark-gray);
}
.br-medium-gray {
	border-color: var(--medium-dark);
}
.br-light-gray {
	border-color: var(--light-dark);
}

.br-dirt {
	border-color: var(--dirt);
}
.br-medium-dirt {
	border-color: var(--medium-dark);
}
.br-bright-dirt {
	border-color: var(--bright-dark);
}

/*
	SVG FILLS
*/
.svg-black {
	fill: var(--black);
}
.svg-white {
	fill: var(--white);
}

.svg-red {
	fill: var(--red);
}
.svg-red-fade {
	fill: var(--red-fade);
}

.svg-green {
	fill: var(--green);
}
.svg-green-fade {
	fill: var(--green-fade);
}

.svg-yellow {
	fill: var(--yellow);
}
.svg-yellow-fade {
	fill: var(--yellow-fade);
}

.svg-orange {
	fill: var(--orange);
}
.svg-orange-fade {
	fill: var(--orange-fade);
}

.svg-purple {
	fill: var(--purple);
}
.svg-purple-fade {
	fill: var(--purple-fade);
}

.svg-blue {
	fill: var(--blue);
}
.svg-blue-fade {
	fill: var(--blue-fade);
}

.svg-light-blue {
	fill: var(--light-blue);
}
.svg-light-blue-fade {
	fill: var(--light-blue-fade);
}

.svg-magenta {
	fill: var(--magenta);
}
.svg-magenta-fade {
	fill: var(--magenta-fade);
}

.svg-gray {
	fill: var(--gray);
}
.svg-dark {
	fill: var(--dark);
}

.svg-dark-gray {
	fill: var(--dark-gray);
}
.svg-medium-gray {
	fill: var(--medium-dark);
}
.svg-light-gray {
	fill: var(--light-dark);
}

.svg-dirt {
	fill: var(--dirt);
}
.svg-medium-dirt {
	fill: var(--medium-dark);
}
.svg-bright-dirt {
	fill: var(--bright-dark);
} /*
  INTER-100 - LATIN-EXT_LATIN
*/
@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 100;
	src: url('../../assets/fonts/inter/inter-v3-latin-ext_latin-100.woff');
	src: local(''),
		url('../../assets/fonts/inter/inter-v3-latin-ext_latin-100.woff2')
			format('woff2'),
		url('../../assets/fonts/inter/inter-v3-latin-ext_latin-100.woff')
			format('woff'),
		url('../../assets/fonts/inter/inter-v3-latin-ext_latin-100.ttf')
			format('truetype');
}
/*
  INTER-200 - LATIN-EXT_LATIN
*/
@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 200;
	src: url('../../assets/fonts/inter/inter-v3-latin-ext_latin-200.woff');
	src: local(''),
		url('../../assets/fonts/inter/inter-v3-latin-ext_latin-200.woff2')
			format('woff2'),
		url('../../assets/fonts/inter/inter-v3-latin-ext_latin-200.woff')
			format('woff'),
		url('../../assets/fonts/inter/inter-v3-latin-ext_latin-200.ttf')
			format('truetype');
}

/*
  INTER-300 - LATIN-EXT_LATIN
*/
@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 300;
	src: url('../../assets/fonts/inter/inter-v3-latin-ext_latin-300.woff');
	src: local(''),
		url('../../assets/fonts/inter/inter-v3-latin-ext_latin-300.woff2')
			format('woff2'),
		url('../../assets/fonts/inter/inter-v3-latin-ext_latin-300.woff')
			format('woff'),
		url('../../assets/fonts/inter/inter-v3-latin-ext_latin-300.ttf')
			format('truetype');
}
/*
  INTER-400 - LATIN-EXT_LATIN
*/
@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	src: url('../../assets/fonts/inter/inter-v3-latin-ext_latin-regular.woff');
	src: local(''),
		url('../../assets/fonts/inter/inter-v3-latin-ext_latin-regular.woff2')
			format('woff2'),
		url('../../assets/fonts/inter/inter-v3-latin-ext_latin-regular.woff')
			format('woff'),
		url('../../assets/fonts/inter/inter-v3-latin-ext_latin-regular.ttf')
			format('truetype');
}
/*
  INTER-500 - LATIN-EXT_LATIN
*/
@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	src: url('../../assets/fonts/inter/inter-v3-latin-ext_latin-500.woff');
	src: local(''),
		url('../../assets/fonts/inter/inter-v3-latin-ext_latin-500.woff2')
			format('woff2'),
		url('../../assets/fonts/inter/inter-v3-latin-ext_latin-500.woff')
			format('woff'),
		url('../../assets/fonts/inter/inter-v3-latin-ext_latin-500.ttf')
			format('truetype');
}
/*
  INTER-600 - LATIN-EXT_LATIN
*/
@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	src: url('../../assets/fonts/inter/inter-v3-latin-ext_latin-600.woff');
	src: local(''),
		url('../../assets/fonts/inter/inter-v3-latin-ext_latin-600.woff2')
			format('woff2'),
		url('../../assets/fonts/inter/inter-v3-latin-ext_latin-600.woff')
			format('woff'),
		url('../../assets/fonts/inter/inter-v3-latin-ext_latin-600.ttf')
			format('truetype');
}
/*
  INTER-700 - LATIN-EXT_LATIN
*/
@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 700;
	src: url('../../assets/fonts/inter/inter-v3-latin-ext_latin-700.woff');
	src: local(''),
		url('../../assets/fonts/inter/inter-v3-latin-ext_latin-700.woff2')
			format('woff2'),
		url('../../assets/fonts/inter/inter-v3-latin-ext_latin-700.woff')
			format('woff'),
		url('../../assets/fonts/inter/inter-v3-latin-ext_latin-700.ttf')
			format('truetype');
}
/*
  INTER-800 - LATIN-EXT_LATIN
*/
@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 800;
	src: url('../../assets/fonts/inter/inter-v3-latin-ext_latin-800.woff');
	src: local(''),
		url('../../assets/fonts/inter/inter-v3-latin-ext_latin-800.woff2')
			format('woff2'),
		url('../../assets/fonts/inter/inter-v3-latin-ext_latin-800.woff')
			format('woff'),
		url('../../assets/fonts/inter/inter-v3-latin-ext_latin-800.ttf')
			format('truetype');
}
/*
  INTER-900 - LATIN-EXT_LATIN
*/
@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 900;
	src: url('../../assets/fonts/inter/inter-v3-latin-ext_latin-900.woff');
	src: local(''),
		url('../../assets/fonts/inter/inter-v3-latin-ext_latin-900.woff2')
			format('woff2'),
		url('../../assets/fonts/inter/inter-v3-latin-ext_latin-900.woff')
			format('woff'),
		url('../../assets/fonts/inter/inter-v3-latin-ext_latin-900.ttf')
			format('truetype');
}
/*
  EJICON
*/
@font-face {
	font-family: 'ejicon';
	font-weight: normal;
	font-style: normal;
	font-display: block;
	src: url('../../assets/fonts/ejicon/ejicon.woff');
	src: local(''),
		url('../../assets/fonts/ejicon/ejicon.ttf') format('truetype'),
		url('../../assets/fonts/ejicon/ejicon.woff') format('woff');
}

[class^='ejicon-'],
[class*=' ejicon-'] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'ejicon' !important;
	/*speak: never;*/
	font-style: normal;
	font-weight: normal;
	font-feature-settings: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.ejicon-applemusic:before {
	font-size: 14px;
	content: '\e900';
}
.ejicon-arrow-down:before {
	font-size: 14px;
	content: '\e901';
}
.ejicon-arrow-left:before {
	font-size: 14px;
	content: '\e902';
}
.ejicon-arrow-right:before {
	font-size: 14px;
	content: '\e903';
}
.ejicon-arrow-up:before {
	font-size: 14px;
	content: '\e904';
}
.ejicon-at:before {
	font-size: 14px;
	content: '\e905';
}
.ejicon-bell:before {
	font-size: 14px;
	content: '\e906';
}
.ejicon-book:before {
	font-size: 14px;
	content: '\e907';
}
.ejicon-bookmark:before {
	font-size: 14px;
	content: '\e908';
}
.ejicon-bulb:before {
	font-size: 14px;
	content: '\e909';
}
.ejicon-bullhorn:before {
	font-size: 14px;
	content: '\e90a';
}
.ejicon-calendar:before {
	font-size: 14px;
	content: '\e90b';
}
.ejicon-calendar-add:before {
	font-size: 14px;
	content: '\e90c';
}
.ejicon-car:before {
	font-size: 14px;
	content: '\e90d';
}
.ejicon-case:before {
	font-size: 14px;
	content: '\e90e';
}
.ejicon-chev-down:before {
	font-size: 14px;
	content: '\e90f';
}
.ejicon-chev-left:before {
	font-size: 14px;
	content: '\e910';
}
.ejicon-chev-right:before {
	font-size: 14px;
	content: '\e911';
}
.ejicon-chev-up:before {
	font-size: 14px;
	content: '\e912';
}
.ejicon-clock:before {
	font-size: 14px;
	content: '\e913';
}
.ejicon-close:before {
	font-size: 14px;
	content: '\e914';
}
.ejicon-communication:before {
	font-size: 14px;
	content: '\e915';
}
.ejicon-compress:before {
	font-size: 14px;
	content: '\e916';
}
.ejicon-crown:before {
	font-size: 14px;
	content: '\e917';
}
.ejicon-document-sign:before {
	font-size: 14px;
	content: '\e918';
}
.ejicon-download:before {
	font-size: 14px;
	content: '\e919';
}
.ejicon-edit:before {
	font-size: 14px;
	content: '\e91a';
}
.ejicon-envelope:before {
	font-size: 14px;
	content: '\e91b';
}
.ejicon-envelope-alt:before {
	font-size: 14px;
	content: '\e91c';
}
.ejicon-exclamation:before {
	font-size: 14px;
	content: '\e91d';
}
.ejicon-exclamation-alt:before {
	font-size: 14px;
	content: '\e91e';
}
.ejicon-exit:before {
	font-size: 14px;
	content: '\e91f';
}
.ejicon-eye:before {
	font-size: 14px;
	content: '\e920';
}
.ejicon-eye-slash:before {
	font-size: 14px;
	content: '\e921';
}
.ejicon-facebook:before {
	font-size: 14px;
	content: '\e922';
}
.ejicon-filter:before {
	font-size: 14px;
	content: '\e923';
}
.ejicon-fullscreen:before {
	font-size: 14px;
	content: '\e924';
}
.ejicon-google-podcast:before {
	font-size: 14px;
	content: '\e925';
}
.ejicon-hand-shake:before {
	font-size: 14px;
	content: '\e926';
}
.ejicon-handshake-2:before {
	font-size: 14px;
	content: '\e927';
}
.ejicon-heart:before {
	font-size: 14px;
	content: '\e928';
}
.ejicon-home:before {
	font-size: 14px;
	content: '\e929';
}
.ejicon-info:before {
	font-size: 14px;
	content: '\e92a';
}
.ejicon-instagram:before {
	font-size: 14px;
	content: '\e92b';
}
.ejicon-label-tag:before {
	font-size: 14px;
	content: '\e92c';
}
.ejicon-link:before {
	font-size: 14px;
	content: '\e92d';
}
.ejicon-link-chain:before {
	font-size: 14px;
	content: '\e92e';
}
.ejicon-linkedin:before {
	font-size: 14px;
	content: '\e92f';
}
.ejicon-lock:before {
	font-size: 14px;
	content: '\e930';
}
.ejicon-lock-button:before {
	font-size: 21px;
	content: '\e931';
}
.ejicon-map:before {
	font-size: 14px;
	content: '\e932';
}
.ejicon-members:before {
	font-size: 14px;
	content: '\e933';
}
.ejicon-men:before {
	font-size: 14px;
	content: '\e934';
}
.ejicon-mentor:before {
	font-size: 14px;
	content: '\e935';
}
.ejicon-menu:before {
	font-size: 14px;
	content: '\e936';
}
.ejicon-money-bill:before {
	font-size: 14px;
	content: '\e937';
}
.ejicon-more:before {
	font-size: 14px;
	content: '\e938';
}
.ejicon-news:before {
	font-size: 14px;
	content: '\e939';
}
.ejicon-pause:before {
	font-size: 14px;
	content: '\e93a';
}
.ejicon-pin:before {
	font-size: 14px;
	content: '\e93b';
}
.ejicon-plus:before {
	font-size: 14px;
	content: '\e93c';
}
.ejicon-question:before {
	font-size: 14px;
	content: '\e93d';
}
.ejicon-return:before {
	font-size: 14px;
	content: '\e93e';
}
.ejicon-search:before {
	font-size: 14px;
	content: '\e93f';
}
.ejicon-self-knowledge:before {
	font-size: 14px;
	content: '\e940';
}
.ejicon-settings:before {
	font-size: 14px;
	content: '\e941';
}
.ejicon-share:before {
	font-size: 14px;
	content: '\e942';
}
.ejicon-short-1:before {
	font-size: 14px;
	content: '\e943';
}
.ejicon-smiley:before {
	font-size: 14px;
	content: '\e944';
}
.ejicon-spotify:before {
	font-size: 14px;
	content: '\e945';
}
.ejicon-star:before {
	font-size: 14px;
	content: '\e946';
}
.ejicon-teacher:before {
	font-size: 14px;
	content: '\e947';
}
.ejicon-tick:before {
	content: '\e948';
}
.ejicon-unlock:before {
	font-size: 14px;
	content: '\e949';
}
.ejicon-user:before {
	font-size: 14px;
	content: '\e94a';
}
.ejicon-user-delete:before {
	font-size: 14px;
	content: '\e94b';
}
.ejicon-user-tick:before {
	font-size: 14px;
	content: '\e94c';
}
.ejicon-women:before {
	font-size: 14px;
	content: '\e94d';
}
.ejicon-youtube:before {
	font-size: 14px;
	content: '\e94e';
}
.arrow-down {
	transform: rotate(360deg);
	transition: transform 0.5s linear;
}

.arrow-down.open {
	transform: rotate(180deg);
	transition: transform 0.5s linear;
}
