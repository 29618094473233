.filter {
    padding-bottom: 20px;
}

.events_title {
    text-align: center;
}

.event_calendar {
    background: #FFFFFF;
    box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.08);
    border-radius: 32px;
    padding:24px;
    align-content: center;
    text-align: center;
}

.react-calendar {
    padding-top: 10px;
    border: 0px solid #ffffff;
}

.react-calendar__navigation {
    color: var(--red);
}

.react-calendar__tile {
    background: transparent;
    border: 0px;
    padding-left: 3px;
    padding-top: 4px;
    margin-top: 10px;
    margin-bottom: 2px;
    text-align: center;
    z-index: 10;
    vertical-align: top;
    line-height: 32px;
}


.react-calendar__tile--active > .tile_active_my {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    background: var(--red);
    border-radius: 50%;
    margin: -32px auto 0 auto;
    
    z-index: 8;
}


.react-calendar__tile--active > abbr {
    color: white;
    z-index: 10;
}


.react-calendar__tile--active:enabled:hover:visited > abbr{
    z-index: 10;
    color: white;
}



.event_calendar_mobile_container {

}

.react-calendar__navigation__label__labelText {
    /* Heading/5 */

font-family: Inter;
font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 26px;
color: var(--red);
}

.react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button {
    display: none;
}

.react-calendar__navigation__arrow {
    font-size:xx-large;
    color: var(--red);
    background: transparent;
    border:none;
}

.calendar_mobile {
    align-content: center;
    text-align: center;
    justify-content: center;
    display: none;
}

.calendar_tile_myclass::after {
    background-color: var(--red) !important;
}

.calendar_potty {
    width:4px !important;
    height:4px !important;
    border-radius: 2px !important;
   /*  background-color: var(--red); */
    float:left;
    margin-right:2px;
    margin-left:2px;
}

.calendar_potty_row {
    text-align: center;
    align-items: center;
    vertical-align: bottom;
    margin: 0 auto;
    width: fit-content;
    margin-top:2px;
    min-height: 4px;
}

.react-calendar__month-view__weekdays__weekday > abbr[title]{
    text-emphasis: none;
    text-decoration: none;
    color: #A9A8AE;
}

.react-calendar__navigation__label {
    pointer-events: none;
    background: transparent;
    border: 0px;
}

.react-calendar__month-view__weekdays {
    margin-top: 6px;
    margin-bottom: 6px;
}

@media (min-width: 1400px) {
    
}

@media (min-width: 1200px) {

}

@media (min-width: 992px) {
    
}
 
@media (max-width: 768px) {
    .calendar_mobile {
        align-content: center;
        text-align: center;
        justify-content: center;
        display:block;
    }

    .event_calendar {
        display:none;
    }
    .extra_filter_container {
        right:0px;
    }
}

.extra_filter_container {
    background: #FFFFFF;
    box-shadow: 16px 16px 60px rgb(0 0 0 / 8%);
    border-radius: 32px;
    padding: 24px;
    align-content: center;
    text-align: center;
}

.event_calendar_small {
    background: #FFFFFF;
    box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.08);
    border-radius: 32px;
    padding:24px;
    align-content: center;
    text-align: center;
    font-size: 12px;
    position: absolute;
    z-index: 2;
    left: 7%;
    right: 7%;
}

.megnezem_naptar_btn > button.btn_secondary:hover:not(.btn_disabled){
    background: #FFFFFF;
    border: 2px solid #F5F6F7 !important;

}