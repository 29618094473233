.radio_button_container{
    height: 20px;
    width: 20px;
    background-color: #ffffff;  
    box-sizing: border-box;
    border-radius: 50%;
}


.radio_button_red{
    border: 1px solid var(--red);
}

.radio_button_grey{
    border: 1px solid #D4D6DB; 
}


.centered_circle.circle_red::before{
    font-size: 14px;
    color: var(--red);
    background-color: var(--red);
    border-radius: 7px;
    
}

.centered_circle.circle_grey::before{
    font-size: 14px;
    color: #D4D6DB;
    background-color: #D4D6DB;
    border-radius: 50%;
}

.big_radio_button_container{
    background: #FFFFFF;
    border-radius: 16px;
}

.radio_button_checked{
    background: #FFFFFF;
    border: 1px solid #FFFFFF;

/* DS 01 */
box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.08);
}

.radio_button_unchecked{
    background: #FFFFFF;
    border: 1px solid #D4D6DB;
}
.radio_button_disabled{
    background: #F5F6F7;
}

.radio_button:hover{
     cursor: pointer;
}

.right_icon_red::before {
    color: var(--red);
}
.right_icon_grey::before {
    color: #D4D6DB;;
}