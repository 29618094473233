.checkbox {
    height: 20px;
    width: 20px;
    min-width: 20px;
    border-radius: 6px;
}

.checkbox_dark_grey{
    background-color: #D4D6DB;
}

.checkbox_light_grey{
    background-color:#EFEFF0;
}

.checkbox_red{
    background-color: var(--red);
}

.checkbox_icon::before {
    font-size: 10px;
    color: #FFFFFF;
}

.centered_line {
    width: 12px;
    height: 0px;
    border: 1px solid #FFFFFF;
    background-color: #FFFFFF;
    margin-top: 9px;
}

.form-check-input {
    width:20px !important;
    height: 20px !important;
}