.loader{
    position: relative;
    width: 72px;
    height: 72px;
    float:left;
    user-select: none;
    box-sizing: border-box;
}
.loader-bg
{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 4px solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    
}
.spiner-holder-one
{
    position: absolute;
    top:0;
    left:0;
    overflow: hidden;
    width: 50%;
    height: 50%;
    background: transparent;
    box-sizing: border-box;
}
.spiner-holder-two
{
    position: absolute;
    top:0;
    left:0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background: transparent;
    box-sizing: border-box;
}
.loader-spiner
{
    width: 200%;
    height: 200%;
    border-radius: 50%;
    border: 4px solid #FF9901;
    box-sizing: border-box;
}  
  .animate-0-25-a
{
    transform: rotate(90deg);
    transform-origin: 100% 100%;
}
.animate-0-25-b
{
    transform: rotate(-90deg);
    transform-origin: 100% 100%;
}
.animate-25-50-a
{
    transform: rotate(180deg);
    transform-origin: 100% 100%;
}
.animate-25-50-b
{
    transform: rotate(-90deg);
    transform-origin: 100% 100%;
}
.animate-50-75-a
{
    transform: rotate(270deg);
    transform-origin: 100% 100%;
}
.animate-50-75-b
{
    transform: rotate(-90deg);
    transform-origin:100% 100%;
}
.animate-75-100-a
{
    transform: rotate(0deg);
    transform-origin: 100% 100%;
}
.animate-75-100-b
{
    transform: rotate(-90deg);
    transform-origin: 100% 100%;
}
.text
{
    text-align: center;
    padding-top:32%;     
    color: black;
}