.partner_card_image {
    width:100%;
    height:100%;
    background-color: #ffffff;
    margin:  auto 0 !important;
    padding: 0px;
   /*  padding-top: 10%; */
    
}

.partner_card_image_sinko {
    width:100%;
    height:100%;
    background-color: #ffffff;
    margin:  auto 0 !important;
    padding-top: 0%;
    padding: 0px;
    
}

.partner_card_image_jamba {
    width:100%;
    height:100%;
    background-color: #ffffff;
    margin:  auto 0 !important;
    padding-top: 0%;
    padding: 0px;
}

.partner_card_image_sinko > img{
    padding-top: 0%;
    border-radius: 36px;
}

.partner_card_image > img {
    margin: auto 0px;
  
}

.videoWrapper::before {
    border: 2px solid #ffffff !important;
}

.partner-card-text > p {
    padding-left: 0px !important;
}