.course-preview-container {
    width: 100%;
    min-height: 549px;
    background: #F5F6F7;
    border-radius: 32px;
    position: relative;
}
.course-preview-container .fullscreen {
    position: absolute;
    bottom: 32px;
    right:32px
}
.course-preview-container img {
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
    width: 100%;
    height: 549px;
    object-fit: cover
}
.course_slide_modal_container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: white;
    top: 0;
    left: 0;
    z-index: 99;
}
.course_slide_modal_main-pic {
    position: absolute;
    right: 0;
    top: 0;
    width: 50vw;
    height: 100vh;
    object-fit: cover;
}
.course_slide_modal_side_info_container {
    width: 50vw;
    padding: 4rem;
    height: 100vh;
    display: flex;
    flex-direction: column;
    word-break: break-word;
}
.course_slide_modal_sidebar {
    position: fixed;
    right: 0;
    z-index: 4;
    background: white;
    box-shadow: 16px 16px 60px rgb(0 0 0 / 12%);
    border-bottom-left-radius: 32px;
    border-top-left-radius: 32px;
    padding: 3rem;
    top:4%;
}
.side_info_closed {
    right: -100%;
}
.course_slide_modal_sidebar .sidebar-open-close {
    position: relative;
}
.side_info_closed .sidebar-open-close {
    right: 0;
    position: fixed;
    right: 0;
    position: fixed;
    background: white;
    padding: 1rem;
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
    top:4%;
}
.course_slide_modal_nav {
    position: fixed;
    z-index: 3;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    box-shadow: 16px 16px 60px rgb(0 0 0 / 12%);
    border-top-right-radius: 32px;
    border-top-left-radius: 32px;
    min-width: 242px;
}
.course_slide_modal_close_button {
    position: fixed;
    bottom: 32px;
    right: 32px;
    z-index: 3;
}
@media (max-width: 992px) {
    .course_slide_modal_side_info_container {
        width: 100vw;
        position: fixed;
        z-index: 2;
    }
    .course_slide_modal_main-pic {
        width: 100vw;
        height: 100vh;
        object-fit: cover;
    }
    .course_slide_modal_container::before {
        content:"";
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        background: linear-gradient(358deg, #000000 11.85%, rgba(0, 0, 0, 0) 71.33%);
        z-index: 2;
    }
    .course_slide_modal_side_info_container .text-cc-primary {
        color:white!important;
        font-size: calc(1.3rem + 0.6vw);
        
    }
    .course_slide_title {
        font-size: calc(1.375rem + 1.5vw)!important; 
        color:white!important;
        line-height: inherit;
    }
}
@media (max-width: 425px) {
    .course_slide_modal_close_button {
        display: none;
    }
    .side_info_closed {
        right: -103%;
    }
    .course_slide_modal_sidebar .btn {
        width: 100%;
    }
}