.p-4_5{
    padding: 2.5rem!important;
}
@media (max-width: 992px) {
.p-4_5{
    padding: 1rem !important;
}
.course_detail-sidebar {
    background: transparent!important;
}
.download-my-document button {
    width: 100%;
}
.open-course-arrow {
    height: 24px;
    display: flex;
    align-items: center;
}
}
.course_detail-sidebar {
    background: #F5F6F6;
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
    position: relative;
}
@media (min-width: 992px) {
.course_detail-sidebar .card {
    box-shadow: none;
}
}
@media (max-width: 992px) {
.course_detail-sidebar::after {
    display: none;
}
}
.course_detail-sidebar::after {
    content: "";
    position: absolute;
    top: 0;
    right:-800px;
    background: #F5F6F6;
    width: 800px;
    height: 100%;
}
.course-tab-container .tab_list_2_container{
padding:0;
width: 100%;
justify-content: space-between;
}
.course-tab-container .tab_list_2_link_container:first-child {
    margin-left: 0;
}
{/* Csak prezentációs célból elkészített rész */}
.course-video-container {
    position: relative;
    object-fit: cover;
}

.course-video-container >img{
    object-fit: cover;
}

.course-video-container .progress {
    position: absolute;
    top: 32px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    height: 26px;
    max-width: 632px;
    background: transparent;
}
.course-video-container .play {
    width: 56px;
    height: 56px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    cursor: pointer;
}
{/* Csak prezentációs célból elkészített rész */}

.material_link_container{
    color: #F40009;
}
.course-rating-container hr{
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    border: 1px solid #EFEFF0;
}
.my_avatar {
   width: 56px;
   height: 56px;
   object-fit: cover;
   border-radius: 50%;
}

.tick_to_compact::before{
    color: #F40009;
    font-size: 8px;
}

.course_completion {
   
    bottom: 13px;
    left: 6.25%;
    width: 100%;
    height: 16px;
    background: #FFFFFF;
    /* DS 01 */
    box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.08);
    border-radius: 32px;
    padding:4px;
    display: flex;
    flex-direction: row;
    top:7%;
}
.course_completion_max {
    width: 90%;
    height: 100%;
}

.course_completion_status {
    background: #FF9901;
    border-radius: 32px;
    height: 100%;
}

.course_completion_no {
    height: 100%;
    width: 10%;
    color: #FF9901;
    margin-top:-5px;
    text-align: right;
    font-size: 12px;
}

.letters{
    width: 56px;
    height: 56px;
    object-fit: cover;
    border-radius: 50%;
    color: #FFFFFF;
    background-color: #F40009;
}

.input_text_container > .textarea_field {
    height: 100%;
    width: 100%;
    border: 0px solid #ffffff;
}

#iframe_slide{
    border-radius: 32px;
}

.share_button > button > div {
    cursor:pointer;
}

.share_button > a > div {
    cursor:pointer;
}