.tooltip_container{
    display: flex;
    flex-direction: row;
    align-items: center;
   /* border: 3px solid green;
    border-radius: 16px;*/
    width: 273px;
}

.tooltip_icon_info {
    background: url('../../assets/icons/tooltip/info.png');
    width: 16px;
    height: 16px;
    display: block;
    margin-right: 12px;
    margin-left: 12px;
    /* Other styles here */
}

.tooltip_body{
    align-items: center;
    text-align:center;

    width: 233px;
    height: 40px;
    padding-top: 6px;
    left: 144px;
    top: 376px;
    visibility: hidden;
    /* #12072A */
    background: #12072A;
    color: white;
    border-radius: 16px;
    z-index: 5;
}

.tooltip_show {
    visibility: visible
}