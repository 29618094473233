.page_header {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    height: 104px;
    width: 100%;
    border-bottom: 1px solid #f3f3f4;
}

.logo {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
}

.logo >img {
    border-radius: 0%;
}

.logo_bootle {
    display: inline-flex;
    flex-direction: row;
    padding-left: 6px;
}

.logo_bootle_red {
    margin-left: -2.5px;
    margin-right: -2.5px;
    z-index: 3;
}

.logo_bootle_gray {
    z-index: 2;
}
.logo_bootle_black {
    z-index: 2;
}

.navigation {
    width: 100%;
    z-index:999;
}

.navigation_top {
    z-index:999;
}

.navigation_right_container {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.navigation_right_mobile {
    display: none;
}

.navigation_right_login {
    text-decoration: none;
}

.ikon_with_small{
    display: none;
}

.ikon_with_big{
    display: flex;
    width: 56px;
    height: 56px;
    font-size: 28px;
    background-color:#F5F6F6;
    text-align: center;
    vertical-align: middle;
}

.header_drop_container {
    background: #FFFFFF;
    box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.08);
    border-radius: 32px;
    padding:24px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #12072A;
    width: auto;
    position: absolute;
  background-color: #FFFFFF;
  right: 0px;
}

.profile_button_fix {
    width:100%;
    height:100%;
    padding:0px !important;
}

.left_menu_show {
    left:0% !important;
    padding: 0px !important;
}

.left_menu_show > .row > .cc-menu > .cc-navigation-menu >.cc-navigation-menu-item > .menu-activated {
    color: #ffffff !important;
}

.cc-navigation-menu-link {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    
}

.cc-navigation-menu-item > a, .cc-navigation-menu-item > a:link, .cc-navigation-menu-item > a:hover, .cc-navigation-menu-item > a:visited {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    
}
.cc-navigation-menu-item > a:active {
    color:var(--red);
}


.button_with_logged_in {
    border-radius: 50%;
    background-image: none;
    margin: 0px;
    border:0px solid var(--red);
    padding: 0px !important;
}

.menubtn_kicsiben_latszik {
    display: none;
}
.menubtn_nagyban_latszik {
    display: flex;
}

.droplist_element > span {
    font-size: 18px;
    margin-right: 12px;
    color: var(--red);
    padding-top: 1px;
    width:24px;
}
.droplist_element {
    margin-bottom: 6px;
    vertical-align: middle;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}
/* .ikon_with_big > span {
    padding-top:6px;
    color:  #A9A8AE;
} */

@media only screen and (max-width: 1024px) {
    .page_header {
        display: inline-flex;
        flex-direction: row;
        align-items: flex;
        cursor: pointer;
        padding-top: 8px;
        padding-left: 16px;
        padding-right: 8px;
        width:100%;
    }
    .navigation_big_container{
        display: none;
    }

    .navigation_right_mobile {
        display:flex;
    }

    .navigation_right_login {
        display: none;
        
    }
    
   
}

@media only screen and (max-width: 1199px) {

    .menubtn_kicsiben_latszik {
        display: flex;
    }
    .menubtn_nagyban_latszik {
        display: none;
    }
    .ikon_with_big{
        width: 48px;
        height: 48px;
    }

    .ikon_with_big > span {
        padding-top:2px;
    }
    .header_letters{
        width: 48px;
        height: 48px; 
    }
    .header_drop_container {
        background: #FFFFFF;
        box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.08);
        border-radius: 32px;
        padding:24px;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #12072A;
        width: auto;
        position: absolute;
      background-color: #FFFFFF;
      right: 80px;
    }
}

.menu-activated::before {	
    color: var(--white) !important;
    transform: scaleX(1) !important;	
}

.header_letters{
    width: 56px;
    height: 56px;
    object-fit: cover;
    border-radius: 50%;
    color: #FFFFFF !important;
    background-color: var(--red);
}

@media (max-width: 1199px) {
    .rs-toggle-presentation {
        color: var(--red) !important;
        background-color: var(--white) !important;
    }
}

@media (min-width: 1200px) {
    .rs-toggle-presentation {
        color: var(--white) !important;
        background-color: var(--red) !important;
    }
}

.rs-toggle {
    position: relative;
}
.rs-toggle-lg .rs-toggle-presentation {
    border-radius: 15px;
    height: 30px;
    min-width: 55px;
}
input[type=checkbox], input[type=radio] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}
.rs-toggle-input {
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
}
.rs-toggle-lg.rs-toggle-checked .rs-toggle-presentation .rs-toggle-inner {
    margin-left: 10px;
    margin-right: 30px;
}
.rs-toggle-lg .rs-toggle-inner {
    font-size: 14px;
    height: 30px;
    line-height: 2.14285714;
    margin-left: 30px;
    margin-right: 10px;
}
.rs-toggle-lg .rs-toggle-presentation:after {
    border-radius: 11px;
    height: 22px;
    left: 4px;
    top: 4px;
    width: 22px;
}
.rs-toggle-presentation {
    background-color: #d9d9d9;
    background-color: var(--rs-toggle-bg);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #fff;
    color: var(--rs-toggle-thumb);
    cursor: pointer;
    display: inline-block;
    position: relative;
    -webkit-transition: background-color .2s ease-in-out,width .2s ease-in-out;
    transition: background-color .2s ease-in-out,width .2s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
}
.rs-toggle-lg.rs-toggle-checked .rs-toggle-presentation:after {
    left: 100%;
    margin-left: -26px;
}
.rs-toggle-presentation:after {
    background-color: currentColor;
    content: "";
    cursor: pointer;
    position: absolute;
    -webkit-transition: left .2s ease-in-out,margin-left .2s ease-in-out,width .2s ease-in-out;
    transition: left .2s ease-in-out,margin-left .2s ease-in-out,width .2s ease-in-out;
}