.droplist_container {
    list-style-type:none ;
    background: #FFFFFF;
    box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.08);
    border-radius: 32px;
    padding: 16px 24px;
    
}

.droplist_element {
    cursor: pointer;
    display: flex;
}

.droplist_element.droplist_radio:hover {
    color: var(--red);
}

.droplist_ruler {
    border: 1px solid #EFEFF0;
    opacity: 1;
}

.check_box {
    width: 20px;
    height: 20px;
    background: #EFEFF0;
    border-radius: 6px;
    margin-right: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.selected_box {
    background: var(--red);
    color: #FFFFFF;
    font-size: 10px;
}

.selected_unit {
    color: var(--red);
}
