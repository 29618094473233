* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Inter;
}
.temp_menu {
  width: 1200px;
  display: none;

}
/* @albasa: ideiglenesen kiveszem a menut a mobil nézetből */
@media only screen and (max-width: 600px) {
  /* For mobile phones: */
  .temp_menu {
    display: none;
  }
}

@media (max-width: 1199.99px) {
  .margin_top {
    margin-top: 112px;
  }
}

@media (min-width: 1200px) {
  .margin_top {
    margin-top: 144px;
  }
}

.notifications_container {
  position: absolute;
  right: 12px;
  top: 100px;
  z-index: 98;
}

