.tablist_container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    cursor: pointer; 
    padding-bottom:30px;
    padding-top:20px;
    padding-right:20px;
    /* overflow: scroll; */
    -ms-overflow-style: none;  
    scrollbar-width: none;  
    justify-content: space-around;
}

.tablist_container::-webkit-scrollbar {
    display: none;
}

@media (max-width: 768px){
.tablist_container {
    width: 100%;
    overflow-x: scroll;
    -ms-overflow-style: none !important;  
    scrollbar-width: none !important; 
    padding-bottom:30px;
    padding-top:20px;
    padding-right:20px;
    justify-content: left;
}
}
.tab_container {
    background: #F5F6F7;
    border-radius: 16px;
    padding: 7px 16px;
    margin-left: 12px;
    flex: 0 0 auto;
    transition: 0.25s all linear;
    white-space: nowrap;
    margin-bottom: 15px;
}

.tab_container:hover:not(.active_tab) {
    transform: translateY(-8px);
    /* color: #FFFFFF; */
}

.tab_container.active_tab {
    background: var(--red);
    color: #FFFFFF;
/* Drop Shadow */
    box-shadow: 8px 8px 24px rgba(244, 0, 0, 0.25);
    transform: translateY(-8px);
}

.tab_list_2_container {
    /* background: #FFFFFF; */
   
    /* DS 01 */
    /* box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.08);
    border-radius: 32px;  */
    display: inline-flex;
    flex-direction: row;
    align-items: flex-start;
    cursor: pointer;
    padding: 0px 10px;
}
.tab_list_2_link_container {
    padding: 39px 0px;
    margin: 0px 22px;
    color:  #12072A;
    position: relative;
}

@media (max-width: 768px){
    .tab_list_2_container {
        width: 100%;
        overflow-x: scroll;
    }
    .tab_list_2_container::-webkit-scrollbar {
        display: none;
      }
    .tab_list_2_link_container {
        flex: 0 0 auto;
    }
}
.active_link::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    background: var(--red);
    color: var(--red);
    width: 100%;
}

@media (min-width: 768px){
.tablist_multirow{
    flex-wrap: wrap;
    justify-content: center;  
}
}