@import "~bootstrap/scss/bootstrap";

// No media query necessary for xs breakpoint as it's effectively `@media (min-width: 0) { ... }`
@include media-breakpoint-up(sm) {
    
    }
@include media-breakpoint-up(md) {

}
@include media-breakpoint-up(lg) {
    
}
@include media-breakpoint-up(xl) {
    
}
@include media-breakpoint-up(xxl) {
    
}