.mentor-large-radio-container {
    position: relative;
}
.mentor-large-radio-container .content-div {
    position: absolute;
    bottom: 32px;
    left: 32px;
    right: 32px;
    color: white;
}
.mentor-large-radio-container .content-div h2 {
    color: white;
}
.mentor-full-width-container {
    position: relative;
}
.mentor-full-width-container .bg-vector {
    position: absolute;
    left: 0;
    width: 100vw;
    top: 90%;
    transform: translateY(-50%);
    z-index: -11;
}
.mentor-full-width-container .row {
    z-index: 2;
    position: relative;
}

.mentor_nagykep {
    position: absolute;
    right: 0;
    width: 30vw;
    top: -30%;
    z-index: 1;
    height:auto; 
    right:calc(0% - 64px);
}

.mentors_form {
    background: #FFFFFF;
    box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.08);
    border-radius: 32px;
   margin-top:15%;
}

.mentors_form_inputs {
    padding: 64px;
}

.mentors_kapcsolat_kep {
    position: relative;
}

.mentors_form_telephone {
    position:absolute;
    top: 0%;
    left: 0%;
    padding: 24px;
    background: #ffffff;
    border-radius: 50%;
    box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.08);
    width: 88px;
    height: 88px;
    margin-top: -10%;
    margin-left: 10%;
    z-index: 4 !important;
}

.mentors_form_img {
    position: absolute;
    top: -25%;
    left:20%;
    z-index: 2;
    width: 85%;
}

.mentors_form_img > img {
    width:100%;
    left: 10%;
    z-index: -1;
}

.mentor_stats {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.mentor-helper {
    white-space: nowrap;
}
.mentor-card-wrapper {
    width: 100% !important;
    perspective: 1000px;
    background: unset;
    overflow:visible !important;
}

.flip-card {
    padding:10px !important;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  
  .mentor-card-wrapper:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .mentor-card-front, .mentor-card-back  {
      border-radius: 32px;
      box-shadow: var(--shadow-light);
      
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
}

  .mentor-card-front {
    max-height: 100%;
}

.mentor-card-back::-webkit-scrollbar {
    display: none;
}

.mentor-card-back {
    top:0px !important;
    max-height: 100%;
    overflow-y: scroll !important;
    font-size:14px;
    transform: rotateY(180deg);
}

.mentors-list > .col-12 {
    padding: 1%;
}

.body_small_mentor {
   font-size: 14px;
}

.mentor_tags_plus {
    padding-top:4px;
}
.tag > .status-text {
    vertical-align: middle;
}

.mentor_tag_float_left {
    float:left;
    margin-right:12px;
}

@media (max-width: 768px) {
    .mentor-card-wrapper:hover .flip-card-inner {
        transform:none;
      }

      .mentor-card-wrapper_hover .flip-card-inner {
        transform: rotateY(180deg) !important;
      }
}