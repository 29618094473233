.news-detail-container {
    max-width: 800px;
    margin: auto;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}
.new-full-width-container {
    position: relative;
}
.new-full-width-container .bg-vector {
    position: absolute;
    left: 0;
    width: 100vw;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}
.new-full-width-container .news-detail-container {
    z-index: 2;
    position: relative;
}

.share_button > button > div {
    cursor:pointer;
}

.share_button > a > div {
    cursor:pointer;
}