
.newscard_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 40px;
    position: relative;
}

.newscard_container_compact {
   /* width: 280px;
     display: flex;
    flex-direction: column;
    overflow:auto;
    border-radius: 40px; */
}


.newscard_img_elements{
    position: relative;

}

.newscard_img_elements_small{
    position: relative;

}

.newscard_img img  {
   
    object-fit: cover;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.newscard_status {
    float: left;
    margin:24px;
}



.newscard_textbox {
    
    /* #FFFFFF */
    background: #FFFFFF;
    border-radius: 32px;
    padding-left:24px;
    padding-bottom: 24px;
    margin-top: -32px;
    z-index: 2;
    padding-right: 24px;
    margin-bottom: 1.5rem;
}


.newscard_stats {
    display: flex;
    flex-direction: row;
    width:100%;
    padding-top: 20px;
    padding-bottom: 12px;
    padding-right: 25px;
    padding-left: 5px;
    color: #646568;
    align-items: center;

}

.newscard_stats >.ejicon-calendar::before {
    font-size: 16px;
    color: #646568;
}

.newscard_date_place {
    padding-left: 13px;
}


.newscard_desc {
    padding-top: 8px;
    color: #646568;
}


@media only screen and (max-width: 600px) {
    /* For mobile phones: */
    .newscard_container {
   
        display: flex;
        flex-direction: column;
        overflow:auto;
        border-radius: 40px;
    }

    .newscard_img_elements{
        position: relative;
        
        width:100%;
    }

    .newscard_container_compact {
       
    }
}
