
.link_vector {
    padding-right: 11px;
}

.link_with_icon { 
    
    /* Inside Auto Layout */
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 8px;
}

/* .link_with_icon:hover {
    color: #F40000;
} */



a.link_with_icon {
    display: flex;
    text-decoration: none;
    height: 26px;
    margin: auto;
    color: var(--red);
    align-items: center;
}
a.link_with_icon:hover {
    
    color: var(--red);
}
.link_icon {
    width: 18px;
    height: 21px;
    margin-right: 11px;
    display: flex;
    align-items: center;
}

a.link_with_icon.link_active{
    color: var(--red);
}