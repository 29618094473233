/*star*/
.icon_opinion_star {
    background: url('../../../assets/icons/opinion/star.png');
    height: 33px;
    width: 32px;
    display: block;
    /* Other styles here */
}

/*star_filled*/
.icon_opinion_star_filled {
    background: url('../../../assets/icons/opinion/star_filled.png');
    height: 33px;
    width: 32px;
    display: block;
    /* Other styles here */
}