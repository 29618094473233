@import "~bootstrap/scss/bootstrap";

#root {
    width: 100%;
    overflow-x: hidden;
}

@include media-breakpoint-up(xs) {

}

@include media-breakpoint-up(sm) {

}

@include media-breakpoint-up(md) {

}

@include media-breakpoint-up(lg) {

}

@include media-breakpoint-up(xl) {
   
}

@include media-breakpoint-up(xxl) {
    
}
