 /* The switch - the box around the slider */
 .switch {
    position: relative;
    display: inline-block;
    width: 53px;
    height: 32px;
  }
  
  /* Hide default HTML toggle */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:not(.toggle_disabled){
    background-color: #EFEFF0;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 5px;
    bottom: 5px;
    
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:not(.toggle_disabled):before{
    background-color: #FFFFFF;
  }
  .slider.toggle_disabled:before{
    background-color: #EFEFF0;
  }


  input:checked + .slider:not(.toggle_disabled) {
    background-color: var(--red);
  }
  
  /* input:focus + .slider {
    box-shadow: 0 0 1px var(--red);
  } */
  
  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  } 

  /* .slider.toggle_disabled {
      background-color: #EFEFF0;
  }
 */
  input + .toggle_disabled {
    background-color: #FFFFFF;
    border: 1px solid #EFEFF0;
    filter: drop-shadow(2px 6px 40px rgba(0, 0, 0, 0.07));
    border-radius: 24px;
  }