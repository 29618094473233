.footer {
    width: 100%;
    background-image: url(../../assets/img/vector3_grey.svg);
    background-position-y:top;
    background-size: 100%;
    padding-top: 240px;
    overflow:auto;
}

.footer_container {
    margin-bottom: 12px;
}

.footer_menu_item > a, .footer_menu_item > a:hover, .footer_menu_item > a.link {
    color: #12072A;
    text-decoration: none;
    font-family: Inter;
    font-style: normal;
    
    font-size: 16px;
    line-height: 24px;
}


.footer_container_left_text_field_bold{
    /* Body/Normal */
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    /* identical to box height, or 150% */

    /* #12072A */
    color: #12072A;

    padding-top: 10px;
}

.footer_container_social_media_text_field_bold{
    /* Body/Normal */
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    /* identical to box height, or 150% */

    /* #12072A */
    color: #12072A;

    padding-bottom: 10px;
    padding-top: 10px;

 /*   border: 3px solid darkblue;
    border-radius: 16px;*/
}

.footer_container_left_text_field {
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #12072A;
    margin-bottom: 8px;
}

.footer_menu_title {
    margin-bottom: 16px;
}

.footer_menu_item {
    margin-bottom: 8px;
}

.footer_icons {
    margin-bottom: 24px;
}

.footer_icons > h5 {
    margin-bottom: 12px;
}

.footer_icons > .col-2 {
    margin-right: 12px;
}

.footer_icons_link {
    text-align: start;
    cursor: pointer;
}

.footer_icons_link > a{
    cursor: pointer;
}

.footer_icons_link > a, .footer_icons_link > a:hover, .footer_icons_link > a:link {
    font-size: 16px;
}

.footer_logoc {
    margin-bottom: 24px;
}

.footer_logoc_row {
    margin-bottom: 12px;
    text-align: start;
}

.footer_logoc_row > img {
    width: 90%;
    padding: 0px;
    padding-left:10px;
    border-radius: unset;
}

.w-100 {
    margin-bottom: 16px;
}
.footer_list {
    flex-grow: 1;

/*    border: 3px solid darkgreen;
    border-radius: 16px;*/
}

/***coca cole logo start****/
.footer_coca_cola_logo_container{
    padding-bottom: 16px;
    
}

.footer_bootle_container {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.footer_icon_gray_bottle {
    background: url('../../assets/footer/gray_bottle.png');
    width: 14px;
    height: 46px;
    display: block;
    z-index: 2;
    /* Other styles here */
}

.footer_icon_red_bottle {
    background: url('../../assets/footer/red_bottle.png');
    width: 18px;
    height: 58px;
    display: block;
    margin-left: -4px;
    margin-right: -4px;
    z-index: 3;
    /* Other styles here */
}

.footer_icon_black_bottle {
    background: url('../../assets/footer/black_bottle.png');
    width: 15px;
    height: 46px;
    display: block;
    z-index: 2;
    /* Other styles here */
}

.footer_coca_cola_logo_text_field{
    padding-left: 10%;
 /*   border: 3px solid darkblue;
    border-radius: 16px;*/
}

.footer_coca_cola_logo_text_field_up {
    background: url('../../assets/footer/coca_cola.svg');
    width: 181px;
    height: 21px;
    display: block;
    margin-bottom: 7px;
    margin-top: 7px;
}

.footer_coca_cola_logo_text_field_down {
    background: url('../../assets/footer/magyarorszag.svg');
    width: 168px;
    height: 25px;
    display: block;
}
/***coca cole logo end****/

.footer_socielmedia_container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

 /*   border: 3px solid darkgreen;
    border-radius: 16px;*/
}

.footer_socielmedia_logo_container {
    display: flex;
    flex-direction: row;

/*    border: 3px solid brown;
    border-radius: 16px;*/
}

/***social media icons start*/
.footer_social_logo_facebook {
    background: url('../../assets/footer/facebook.png');
    width: 32px;
    height: 32px;
    display: block;
    margin-right: 16px;
}

.footer_social_logo_instagram {
    background: url('../../assets/footer/instagram.png');
    width: 32px;
    height: 32px;
    display: block;
    margin-right: 16px;
}

.footer_social_logo_youtube {
    background: url('../../assets/footer/youtube.png');
    width: 32px;
    height: 32px;
    display: block;
}

.footer_social_logo_valami {
    background: url('../../assets/footer/valami.png');
    width: 32px;
    height: 32px;
    display: block;
    margin-right: 16px;
}

.footer_social_logo_spotify {
    background: url('../../assets/footer/spotify.png');
    width: 32px;
    height: 32px;
    display: block;
    margin-right: 16px;
}

.footer_social_logo_music {
    background: url('../../assets/footer/music.png');
    width: 32px;
    height: 32px;
    display: block;
    margin-right: 16px;
}

.footer_social_logo_pontok {
    background: url('../../assets/footer/pontok.png');
    width: 32px;
    height: 32px;
    display: block;
    margin-right: 16px;
}

/***social media icons stop*/

.footer_link_bold {
    font-weight: 600;
}
.hibajelentes_problema_1 > .input_container {
    
    align-items: flex-start;
}

.hibajelentes_problema_1 > .input_container > .input_text_container {
    height:100%;
    align-items: flex-start;
    width:100%;
}

.hibajelentes_problema_1 > .input_container > .input_text_container > .textarea_field {
    height: 100%;
    width:100% !important;
    border:0px solid #ffffff;
}

.hibajelentes_problema_1 > .input_container > .input_text_container > .textarea_field:active {
    height: 100%;
    width:100%;
    border:0px solid #ffffff;
}


.hibajelentes_problema > .input_container {
    height:180px;
    align-items: flex-start;
}

.hibajelentes_problema > .input_container > .input_text_container {
    height:100%;
    align-items: flex-start;
    width:100%;
}

.hibajelentes_problema > .input_container > .input_text_container > .textarea_field {
    height: 100%;
    width:100%;
    border:0px solid #ffffff;
}

.hibajelentes_problema > .input_container > .input_text_container > .textarea_field:active {
    height: 100%;
    width:100%;
    border:0px solid #ffffff;
}

textarea {
    border: none;
    overflow: auto;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    resize: none; /*remove the resize handle on the bottom right*/
}
.footer_icons_link > a > .tag_container{
    cursor: pointer;
}

@media (max-width: 576px) {
    .footer {
        width: 100%;
        background-image: url(../../assets/img/vector3_grey.svg);
        background-position-y:top;
        background-repeat: no-repeat;
        padding-top: 100px;
    }
}


@media (min-width: 768px) {

}


@media (min-width: 992px) {
    .footer {
        width: 100%;
        background-image: url(../../assets/img/vector3_grey.svg);
        background-position-y:top;
        background-size: 100%;
        padding-top: 240px;
        overflow:auto;
    }
}

@media (min-width: 1200px) {

}

@media (min-width: 1400px) {
    .footer {
        width: 100%;
        background-image: url(../../assets/img/vector3_grey.svg);
        background-position-y:top;
        background-size: 100%;
        padding-top: 360px;
        overflow:auto;
    }
}

.upload_file_container:hover{
   cursor: pointer;
}