.icon_badge_cup {
    background: url('../../../assets/icons/badge/cup.png');
    height: 33px;
    width: 32px;
    display: block;
    /* Other styles here */
}
.icon_badge_gold_medal_star {
    background: url('../../../assets/icons/badge/gold_medal_star.png');
    height: 33px;
    width: 32px;
    display: block;
    /* Other styles here */
}

/*gold_medal.png*/
.icon_badge_gold_medal {
    background: url('../../../assets/icons/badge/gold_medal.png');
    height: 33px;
    width: 32px;
    display: block;
    /* Other styles here */
}

/*silver_medal.png*/
.icon_badge_silver_medal {
    background: url('../../../assets/icons/badge/silver_medal.png');
    height: 33px;
    width: 32px;
    display: block;
    /* Other styles here */
}

/*silver_medal.png*/
.icon_badge_bronze_medal {
    background: url('../../../assets/icons/badge/bronze_medal.png');
    height: 33px;
    width: 32px;
    display: block;
    /* Other styles here */
}

/*silver_medal.png*/
.icon_badge_gold_medal_alt {
    background: url('../../../assets/icons/badge/gold_medal_alt.png');
    height: 33px;
    width: 32px;
    display: block;
    /* Other styles here */
}

