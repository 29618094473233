.login_greetings_container {
    padding: 8% 0px;
}

.login_container {
    min-height: 70%;
    padding-left:112px;
}
.login_folytasd {
    color: #646568;
}

.login_bejelentkezes {
    padding: 6% 0px;
}
.input_holder {
    padding: 1% 0px;
}
.login_forgot {
    padding: 2% 0px;
    width: 100%
}
.login_stay {
    padding-right: 4.5%;    
}

.login_register {
    padding: 8% 0px;
    width: 100%
}

.login_not_registered {
    padding-right: 4.5%;
}

.login_button {
    padding-top: 6%;
}

.login_page_changer {
    color: var(--red);
}

.login_page_changer:hover {
    cursor: pointer;
    color: var(--red);
}
.login_apllause_emoji{
    padding-right: 8%;
}


.login_picture_container_inside {
    position: relative;
    padding: 0px !important;
}

.login_picture_container_inside > img {
    border-radius: 0%;
    margin-top: -112px;
}



.login_checkbox {
    background-color: #EFEFF0 !important;
    border: none !important;
    border-radius: 6px;
    padding:0px !important;
}

.login_checkbox_1 {
    background-color: #EFEFF0 !important;
    border: none !important;
    border-radius: 6px;
    padding:0px !important;
    margin-top: 0px !important;
}

.container-border {
    border: 1px solid #EFEFF0;
    border-radius: 14px;
    padding: 14px;
    align-items: center;
}

.login_marad {
    padding-left: 12px !important;
    white-space: nowrap;
}
.form-check-input:checked[type="checkbox"] {
    background-color: var(--red) !important;
}

.login_question {
    color: #12072A;
}
.login_header_text {
    margin-left: 1vw;
}

.milyen_nem {
    white-space: nowrap;
    width: min-content;
    float:left;
    margin-right: 1%;
    margin-top: 10px;
}
.radio_button {
    white-space: nowrap;
    width: min-content;
}

.milyen_nem_clear {
    clear:both;
}

.reg_text {
    padding-right: 10px;
}

.reg_van_mar_text {
    color: #12072A;
    white-space: nowrap;
    margin-right: 5%;
}

.login_responsive_img {
    margin-right:-20px;
}
.login_maximum {
    z-index: 1;
    color: #ffffff;
    background-color: var(--red);
    position: relative;
    padding: 24px;
    border-radius: 50px 0 0 50px;
    position: absolute;
    bottom: 30px;
    right: 0;
    text-align: start;
    margin-right:-20px;
    padding-right: 20%;
}
.login_hozd {
    white-space: nowrap;
    text-align: end;
    width: min-content;
}

.login_picture_container_inside {
    align-items: flex-end;
}
@media (max-width: 1024px) {

    .login_container {
        min-height: 70%;
        padding-left:3%;
    }

    .login_hozd {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        /* or 133% */

        text-align: right;
        color: #FFFFFF;
        text-align: end;
        width: min-content;
        white-space: nowrap;
        text-align: end;
    }

    .login_greetings_container {
        padding: 1% 0px;
        line-height: 40px;
    }

    .login_greetings_container >h1 {
        line-height: 40px;
    }
    .login_picture_container_inside {
        position: relative;
        padding: 0px !important;
    }
    
    .login_picture_container_inside > img {
        border-radius: 0%;
        margin-top: -112px;
        width: 90%;
    }
}