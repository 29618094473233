/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.light-mode {
	--red: #f40009;
	--green: #13c767;
	--green-bg: #e7f9f0;
	--yellow: #e1d800;
	--yellow-bg: #fef7d5;
	--purple: #933cea;
	--purple-bg: #f5ecfd;
	--orange: #ff9901;
	--orange-bg: #fff5e6;
	--blue: #0070f4;
	--blue-bg: #e6f1fe;
	--light-blue: #01c2ff;
	--light-blue-bg: #e6f9ff;
	--pink: #ea50d1;
	--pink-bg: #ffe6f9;
	--cc-qoute-img: url(./assets/images/svg/quote-red.svg);
}
.dark-mode {
	--red: #a6262a;
	--green: #017e3c;
	--green-bg: #f1f9f3;
	--yellow: #e4b408;
	--yellow-bg: #fffced;
	--purple: #8144bf;
	--purple-bg: #f2ecf8;
	--orange: #cc6c13;
	--orange-bg: #fff4ea;
	--blue: #1162c2;
	--blue-bg: #ebf4fe;
	--light-blue: #02789c;
	--light-blue-bg: #eef7fa;
	--pink: #ba36a4;
	--pink-bg: #fcf2fa;
	--cc-qoute-img: url(./assets/images/svg/quote-dark-red.svg);
}
body {
	font-family: 'Inter', sans-serif;
	overflow-x: hidden;
}
hr {
	background-color: #f5f6f6 !important;
	opacity: 1 !important;
}
img,
svg {
	border-radius: 24px;
}
.no-rounded {
	border-radius: 0 !important;
}
.font-size-16 {
	font-size: 16px;
}
.text-cc-primary {
	color: var(--red) !important;
}
.bordered-top {
	border-top: 1px solid #efeff0 !important;
}
.card {
	background: #ffffff;
	box-shadow: 16px 16px 60px rgb(0 0 0 / 8%);
	border-radius: 32px !important;
	border: none !important;
}
.btn-primary {
	background-color: var(--red) !important;
	border-radius: 48px;
}
.btn-white {
	box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.12);
}
ul.list-style-none {
	list-style: none;
	padding: 0;
	margin: 0;
}
@media (min-width: 1400px) {
	.container {
		max-width: 1221px !important;
	}
}
@media (min-width: 992px) {
	.text-lg-left {
		text-align: left !important;
	}
}
@media (max-width: 992px) {
	.text-lg-left {
		text-align: center !important;
	}
}
@media (max-width: 768px) {
	.taglist_container {
		width: 100%;
		overflow-x: scroll;
		padding-top: 16px;
		padding-bottom: 16px;
	}
}
.taglist_container .tag_container {
	overflow: hidden;
	flex: 0 0 auto;
}
.todo-icon-container {
	height: 56px;
	display: flex;
	align-items: center;
}
.todo-input-container {
	width: inherit;
}
.todo-input-container > div:nth-child(2) {
	display: none;
}

.dropdown-item.active,
.dropdown-item:active {
	background-color: var(--red) !important;
}

.first-capitalize:first-letter {
	text-transform: capitalize;
}
