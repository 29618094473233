@font-face {
  font-family: 'ejicon';
  src:  url('../assets/fonts/ejicon.eot?42x5y9');
  src:  url('../assets/fonts/ejicon.eot?42x5y9#iefix') format('embedded-opentype'),
    url('../assets/fonts/ejicon.ttf?42x5y9') format('truetype'),
    url('../assets/fonts/ejicon.woff?42x5y9') format('woff'),
    url('../assets/fonts/ejicon.svg?42x5y9#ejicon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^="ejicon-"], [class*=" ejicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ejicon' !important;
  /*speak: never;*/
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ejicon-applemusic:before {
  font-size: 14px;
  content: "\e900";
}
.ejicon-arrow-down:before {
  font-size: 14px;
  content: "\e901";
}
.ejicon-arrow-left:before {
  font-size: 14px;
  content: "\e902";
}
.ejicon-arrow-right:before {
  font-size: 14px;
  content: "\e903";
}
.ejicon-arrow-up:before {
  font-size: 14px;
  content: "\e904";
}
.ejicon-at:before {
  font-size: 14px;
  content: "\e905";
}
.ejicon-bell:before {
  font-size: 14px;
  content: "\e906";
}
.ejicon-book:before {
  font-size: 14px;
  content: "\e907";
}
.ejicon-bookmark:before {
  font-size: 14px;
  content: "\e908";
}
.ejicon-bulb:before {
  font-size: 14px;
  content: "\e909";
}
.ejicon-bullhorn:before {
  font-size: 14px;
  content: "\e90a";
}
.ejicon-calendar:before {
  font-size: 14px;
  content: "\e90b";
}
.ejicon-calendar-add:before {
  font-size: 14px;
  content: "\e90c";
}
.ejicon-car:before {
  font-size: 14px;
  content: "\e90d";
}
.ejicon-case:before {
  font-size: 14px;
  content: "\e90e";
}
.ejicon-chev-down:before {
  font-size: 14px;
  content: "\e90f";
}
.ejicon-chev-left:before {
  font-size: 14px;
  content: "\e910";
}
.ejicon-chev-right:before {
  font-size: 14px;
  content: "\e911";
}
.ejicon-chev-up:before {
  font-size: 14px;
  content: "\e912";
}
.ejicon-clock:before {
  font-size: 14px;
  content: "\e913";
}
.ejicon-close:before {
  font-size: 14px;
  content: "\e914";
}
.ejicon-communication:before {
  font-size: 14px;
  content: "\e915";
}
.ejicon-compress:before {
  font-size: 14px;
  content: "\e916";
}
.ejicon-crown:before {
  font-size: 14px;
  content: "\e917";
}
.ejicon-document-sign:before {
  font-size: 14px;
  content: "\e918";
}
.ejicon-download:before {
  font-size: 14px;
  content: "\e919";
}
.ejicon-edit:before {
  font-size: 14px;
  content: "\e91a";
}
.ejicon-envelope:before {
  font-size: 14px;
  content: "\e91b";
}
.ejicon-envelope-alt:before {
  font-size: 14px;
  content: "\e91c";
}
.ejicon-exclamation:before {
  font-size: 14px;
  content: "\e91d";
}
.ejicon-exclamation-alt:before {
  font-size: 14px;
  content: "\e91e";
}
.ejicon-exit:before {
  font-size: 14px;
  content: "\e91f";
}
.ejicon-eye:before {
  font-size: 14px;
  content: "\e920";
}
.ejicon-eye-slash:before {
  font-size: 14px;
  content: "\e921";
}
.ejicon-facebook:before {
  font-size: 14px;
  content: "\e922";
}
.ejicon-filter:before {
  font-size: 14px;
  content: "\e923";
}
.ejicon-fullscreen:before {
  font-size: 14px;
  content: "\e924";
}
.ejicon-google-podcast:before {
  font-size: 14px;
  content: "\e925";
}
.ejicon-hand-shake:before {
  font-size: 14px;
  content: "\e926";
}
.ejicon-handshake-2:before {
  font-size: 14px;
  content: "\e927";
}
.ejicon-heart:before {
  font-size: 14px;
  content: "\e928";
}
.ejicon-home:before {
  font-size: 14px;
  content: "\e929";
}
.ejicon-info:before {
  font-size: 14px;
  content: "\e92a";
}
.ejicon-instagram:before {
  font-size: 14px;
  content: "\e92b";
}
.ejicon-label-tag:before {
  font-size: 14px;
  content: "\e92c";
}
.ejicon-link:before {
  font-size: 14px;
  content: "\e92d";
}
.ejicon-link-chain:before {
  font-size: 14px;
  content: "\e92e";
}
.ejicon-linkedin:before {
  font-size: 14px;
  content: "\e92f";
}
.ejicon-lock:before {
  font-size: 14px;
  content: "\e930";
}
.ejicon-lock-button:before {
  font-size: 21px;
  content: "\e931";
}
.ejicon-map:before {
  font-size: 14px;
  content: "\e932";
}
.ejicon-members:before {
  font-size: 14px;
  content: "\e933";
}
.ejicon-men:before {
  font-size: 14px;
  content: "\e934";
}
.ejicon-mentor:before {
  font-size: 14px;
  content: "\e935";
}
.ejicon-menu:before {
  font-size: 14px;
  content: "\e936";
}
.ejicon-money-bill:before {
  font-size: 14px;
  content: "\e937";
}
.ejicon-more:before {
  font-size: 14px;
  content: "\e938";
}
.ejicon-news:before {
  font-size: 14px;
  content: "\e939";
}
.ejicon-pause:before {
  font-size: 14px;
  content: "\e93a";
}
.ejicon-pin:before {
  font-size: 14px;
  content: "\e93b";
}
.ejicon-plus:before {
  font-size: 14px;
  content: "\e93c";
}
.ejicon-question:before {
  font-size: 14px;
  content: "\e93d";
}
.ejicon-return:before {
  font-size: 14px;
  content: "\e93e";
}
.ejicon-search:before {
  font-size: 14px;
  content: "\e93f";
}
.ejicon-self-knowledge:before {
  font-size: 14px;
  content: "\e940";
}
.ejicon-settings:before {
  font-size: 14px;
  content: "\e941";
}
.ejicon-share:before {
  font-size: 14px;
  content: "\e942";
}
.ejicon-short-1:before {
  font-size: 14px;
  content: "\e943";
}
.ejicon-smiley:before {
  font-size: 14px;
  content: "\e944";
}
.ejicon-spotify:before {
  font-size: 14px;
  content: "\e945";
}
.ejicon-star:before {
  font-size: 14px;
  content: "\e946";
}
.ejicon-teacher:before {
  font-size: 14px;
  content: "\e947";
}
.ejicon-tick:before {
  
  content: "\e948";
}
.ejicon-unlock:before {
  font-size: 14px;
  content: "\e949";
}
.ejicon-user:before {
  font-size: 14px;
  content: "\e94a";
}
.ejicon-user-delete:before {
  font-size: 14px;
  content: "\e94b";
}
.ejicon-user-tick:before {
  font-size: 14px;
  content: "\e94c";
}
.ejicon-women:before {
  font-size: 14px;
  content: "\e94d";
}
.ejicon-youtube:before {
  font-size: 14px;
  content: "\e94e";
}
