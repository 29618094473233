@media only screen and (min-width: 1024px) {
    .heading_one, H1{
        font-size: 48px;
    }
    
    .heading_two, H2 {
        font-size: 32px;
    }
    
    .heading_three, H3 {
        font-size: 28px;      
    }
    
    .heading_four, H4 {
        font-size: 24px;      
    }
    
    .heading_five, H5 {
        font-size: 18px;
    }
    
    .heading_six, H6 {
        font-size: 16px;
    }
    
    .body_large {
        font-size: 18px;      
    }
    
    .body_normal {
        font-size: 16px;
    }
    
    .body_small {
        font-size: 14px;
    }
    
    .body_tiny {
        font-size: 12px;
    }
}
.heading_one, H1{
    font-family: Inter;
    font-style: normal;
    line-height: 56px;
}

.heading_two, H2 {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    line-height: 40px;
    
}

.heading_three, H3 {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    line-height: 34px;
    
}

.heading_four, H4 {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    line-height: 32px;
    
}

.heading_five, H5 {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    line-height: 26px;
    
}

.heading_six, H6 {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    line-height: 26px;  
}

.body_large {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    line-height: 26px;  
}

.body_normal {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
}

.body_small {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
}

.body_tiny {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    line-height: 18px;
}

.body_bold {
    font-weight: 600;
}

