
.modal-backdrop {
    background:none;
    background-color: #ffffffff !important;
    border: 0px solid #ffffffff !important;
    -webkit-filter: blur(5px) !important;
    -moz-filter: blur(5px) !important;
    -o-filter: blur(5px) !important;
    -ms-filter: blur(5px) !important;
    filter: blur(5px) !important;
}

.footer_modal > .modal-dialog > .modal-content {
    background:none;
    background-color: #ffffffff !important;
    border: 0px solid #ffffffff !important;
    max-width: 384px;
    margin:0px auto!important;
    padding:0px !important;
    border-radius: 50px;

}

.footer_modal > .modal-dialog > .modal-content >.modal-body {
    margin:0px !important;
    padding:0px !important;
    background:none;
    background-color: #ffffffff !important;
    border-radius: 50%;
    
}

body.modal-open > :not(.modal) {
    -webkit-filter: blur(5px) !important;
    -moz-filter: blur(5px) !important;
    -o-filter: blur(5px) !important;
    -ms-filter: blur(5px) !important;
    filter: blur(5px) !important;
}

body.modal-open {
  
}

.form_box {
    opacity: 1.0;
    background: #FFFFFF;
    box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.08);
    border-radius: 32px;
    padding: 24px;
   
}

.error_button > button {
    width: 100%;
}

.alink_red, .alink_red:hover{
    color: var(--red);
    font-style: normal;
   
}

.body_small > a, .body_small > a:hover, .body_small > a:active, .body_small > a:visited {
    text-decoration: none;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}

.body_small > a:hover {
    text-decoration: none;
    font-family: Inter ;
    font-style: normal;
    font-size: 14px;
    font-weight: 600;
}

.modal-reg-megerosites {
    padding:50px;
    border: 1px solid #cccccc;
    border-radius: 50px;
}