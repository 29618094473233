
.home {
    width: 100%;
}

.home_section_1 {
    position:relative;
}

.landing {
    z-index:1;
}

.home_iframe_style {
    border-radius: 48px;
    box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.08);
    width: 480px;
    height: 300px;
    border: 10px solid #ffffff;
    margin-top: -5%;
}

.landing_bennedvan_nagykep {
    position: absolute;
    right:0;
    top:0;
    margin-top: -180px;
    width:30%;
    z-index: -1 !important;
}

.landing_bennedvan_nagykep >img {
    z-index: -1 !important;
}

.home_bennedvan {
    background-image: url(../../assets/img/Vector9.svg);
    background-repeat: no-repeat;
    height: 688px;
    background-position-y: 40px;
    position: relative;
}

.home_bennedvan_title_sm {
    width: 45%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    color: #12072A;
}

.home_bennedvan_title_md {
    width: 80%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    color: #12072A;
}


.home_bennedvan_text {
    padding-top: 120px;
    padding-left: 112px;
}

.home_bennedvan_desc {
    padding-top: 24px;

}

.home_bennedvan_reg {
    margin-top: 56px;
}


.home_bennedvan_nagykep>.img {
    z-index: 1;
}

.responsive_img {
    width: 100%;
    height: auto;
}

.home_bennedvan_video {
    position: relative;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.08);
    border-radius: 48px;
    padding:17.19px;
}

.icon_play{
    left: 50%;
    top: 50%;
    position: absolute;
    margin-top: -25px;
    margin-left: -25px;
}

.home_stats {
    margin-top: 5vw !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.home_carousel_item {

    padding-bottom:30px;
}

.home_yt_element {
    width: 560px;
    height: 315px;
    border-radius: 48px;
    border: 10px solid #ffffff;
}

.home_stat_element {
    width: 192px;
    /* min-height: 192px; */
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 auto;
    border-radius: 48px;
    padding:10px;
    transition: 0.25s all linear;
}

.home_stat_element:hover {
    box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.08);
    border-radius: 48px;
    transform: translateY(-24px);
}

.home_stat_element_desc {
    max-width: 140px;
    text-align: center;
    font-size: 14px !important;
}

.home_element_icon {
    padding-bottom: 13px;
}

.home_stat_element_icon {
    padding-bottom: 12px;
}

.home_stat_element_icon > .tag_container > .just_icon {
    width: 40px;
    height:40px;

}

.carousel-inner{
    overflow: visible !important;
    height: auto;
}

.home_quotes_main>.carousel-indicators {
    margin-bottom: -40px;
}

.carousel-indicators [aria-current]:not([aria-current="false"]) {
    background-color: var(--red) !important;
  }

.carousel-indicators [aria-current] {
    background-color: var(--red) !important;
}

.carousel-indicators > .current {
    background-color: var(--red) !important;
}

.carousel-indicators [data-bs-target] {
    background-color: #D4D6DB !important;
    width: 8px !important;
    height: 8px !important;

}

.cc-quote {
    margin-bottom: 40px !important;
}

.home_stat_element_no {
    padding-bottom: 4px;
}

.home_tanulhatsz {
    display: flex;
    flex-direction: column;
    align-items: center;
    /*background-image: url(../../assets/img/Vector3.svg);*/
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: 20px;
    /* overflow-x: hidden; */
    margin-top: 5%;
}

.home_tanulhatsz .carousel-inner {
    overflow: hidden !important;
}

.home_tanulhatsz_tab {
    display: flex;

    padding-right: 20px;
    padding-left:0px;
    height: 100%;
    width: 100%;

}

.home_tanulhatsz_tab::-webkit-scrollbar {
    display: none;
}

.home_moduls {
    z-index: 2;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%;
    padding-top: 33px;
    padding-left: 5%;
}

.home_moduls > * {
    margin-right: 36px;
}

.modulcard_container {
    max-width: 460px;
    min-width: 300px;
    margin: 0 auto;
}

.home_tanulhatsz_all {
    height: 80px;
    margin: 0 auto;
}

.home_kikvagyunk {

    overflow:auto;
}


.home_kikvagyunk_text {
    margin-left: -10%;
    padding-top: 64px;
    padding-left: 56px;
    padding-right: 56px;
    /* #FFFFFF */
    background: #FFFFFF;
    /* DS 01 */
    box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.08);
    border-radius: 48px;
}

.home_kikvagyunk_title {
    padding-bottom: 32px;
}

.home_kikvagyunk_desc{
    padding-bottom: 32px;
}

.home_kikvagyunk_btn {
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 40px;
}

.home_kikvagyunk_btn > .btn {
    width:100%;
}

.home_quotes {
    clear: both;
    margin-top: 124px;
    padding-left: 112px;
    padding-right: 112px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 40px;

}

.quotes_equal {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
}

.home_quotes_element {
    width: 100%;

    border: 1px solid #EFEFF0;
    box-sizing: border-box;
    border-radius: 48px;
    margin-right: 2%;
}

.home_quotes_icon {
    padding-top: 32px;
    padding-left: 48px;

}

.home_quotes_text {
    padding-top: 42px;
    padding-left: 40px;
    padding-right: 32px;
    top: 20%;
    left:10%;
}

.home_quotes_author {
    padding-left: 40px;
    padding-right: 32px;
    padding-top: 49px;

}

.home_quotes_author_avatar {
    float: right;

}

.home_quotes_author_left {
    float: left;
}

.home_quotes_author_name {
    color: #12072A;
}

.home_quotes_author_desc {
    color: #646568;
}

@media (min-width: 1200px) {
    .carousel-control-prev,
    .carousel-control-next {
        width: 48px !important;
        height: 48px;
        top: 50% !important;
        bottom: auto !important;
        background-color: #fff;
        border-radius: 100%;
        box-shadow: 0 0 16px rgba(0,0,0,.2);
        opacity: 1 !important;
        transform: translateX(50%);
    }

    .carousel-control-prev {
        left: -6rem !important;
    }

    .carousel-control-next {
        right: -3rem !important;
    }
}

@media (max-width: 1199px) {
    .carousel-control-prev,
    .carousel-control-next {
        width: 48px !important;
        height: 48px;
        top: calc(100% - 56px) !important;
        bottom: auto !important;
        background-color: #fff;
        border-radius: 100%;
        box-shadow: 0 0 16px rgba(0,0,0,.2);
        opacity: 1 !important;
        transform: translateX(50%);
    }

    .carousel-control-prev {
        left: 0 !important;
    }
    
    .carousel-control-next {
        right: 3rem !important;
    }
}



.carousel-control-prev-icon {
    color: var(--red);
    background: var(--red);
    border-radius: 50%;
    width: 56px;
    opacity: 0.0;
}

.carousel-control-next-icon {
    color:var(--red);
    background: var(--red);
    border-radius: 50%;
    width: 56px;
    opacity: 0;
}


.home_podcast {
    overflow:auto;
    position: relative;
    margin-top: 75px;
}

.home_podcast_text {
    position:absolute;
    top: 20%;
    left: 10%;
    width: 45%;
    color: #FFFFFF;

}

.home_podcast_img {
    position:absolute;
    top: 40%;
    left: 10%;
    width: 30%;
}

.home_fejleszd {
    margin-top: 5%;
    margin-bottom: 5%;
}

.home_fejleszd_tittle {
    align-items: center;
    text-align: center;
    margin-bottom: 32px;
}

.home_fejleszd_text {
    margin-left: 5%;
    margin-bottom: 24px;
}

.home_fejleszd_img {
    position:relative;
    width: 100%;
    height:600px;
}

.home_fejleszd_img1_container {
    position: absolute;
    top:0%;
    left:0%;
    width: 50%;
}

.home_fejleszd_img1{
    position: relative;
    top:0%;
    left:0%;
}


.home_fejleszd_img1_1 {
    position: absolute;
    top:0%;
    left:0%;
    background: #FFFFFF;
    padding:20px;
    box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.08);
    border-radius: 50%;
}

.home_fejleszd_img2_container {
    position: absolute;
    top:50%;
    left:0%;
    width: 50%;
}

.home_fejleszd_img2{
    position: absolute;
    top:100%;
    left:0%;
    width: 80%;
}

.home_fejleszd_img2_1{
    position: absolute;
    top:0%;
    right:0%;
    background: #FFFFFF;
    padding:20px;
    box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.08);
    border-radius: 50%;
}

.home_fejleszd_img3_container {
    position: absolute;
    top:25%;
    right:0%;
    width: 50%;
}

.home_fejleszd_img3{
    position: absolute;
    top:0%;
    right:0%;
}

.home_fejleszd_img3_1{
    position: absolute;
    bottom:0%;
    left:10%;
    background: #FFFFFF;
    padding:20px;
    box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.08);
    border-radius: 50%;
}

.home_moduls_group {
    flex: 0 0 auto;
}

.home_quotes_main {
    margin-right:5%;
    margin-left:5%;
}

.home_h1_title {
    line-height: 56px;
}

.buzzsprout-small-player-home {
    border-radius: 24px;
    background-color: #ffffff;
    overflow:hidden;
}

@media (min-width: 1400px) {
    .home {
        display: block;
        width: 100%;
    }

}

@media (min-width: 1200px) {

}

@media (max-width: 992px) {
    .home_quotes_element {
        width: 100%;
        height: 320px;
        border: 1px solid #EFEFF0;
        box-sizing: border-box;
        border-radius: 48px;
        margin-right: 2%;
    }
    .home_moduls {
        z-index: 2;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        width: 100%;
        padding-top: 33px;
        padding-left: 112px;
    }

    .buzzsprout-small-player-home {
        border-radius: 24px;
        background-color: #ffffff;
        max-height: 200px;
        overflow:hidden;
    }
    .landing_fejleszd_title {
        line-height: 40px;
    }
    .home_quotes_main {
        margin-right:1%;
        margin-left:1%;
    }

}

@media (min-width: 768px) {

}

@media (max-width: 576px) {


    .home_h1_title {
        line-height: 40px;
    }
    .home_yt_element {
        width: 460px;
        height: 280px;
        border-radius: 48px;
        border: 10px solid #ffffff;
    }

    .home_content {
        width:100%;
    }

    .home_bennedvan {
        width: 100%;
        height: 100%;
    }

    .home_bennedvan_text {
        width:100%;
        width: 100%;
        padding-top: 120px;
        padding-left: 16px;
    }


    .home_bennedvan_desc {
        width:100%;
    }

    .home_bennedvan_nagykep {
        width:60%;
        margin-top: -132px;
    }

    .home_tanulhatsz {
        height: 100%;
        display: flex;
        flex-direction: column;
        background-image: none;
        background-repeat: no-repeat;
        background-position-y: 20px;
        overflow-x: hidden;
        margin-top: 0%;
    }

    .home_tanulhatsz_all {
        height: 100%;
    }


    .home_moduls {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        height: 100%;
        padding-left:16px;
    }

    .modulcard_container {
        flex: 0 0 auto;

    }


    .home_kikvagyunk_img {

    }

    .home_kikvagyunk_text {
        float: none;
        margin-left: 5%;
        margin-top: -10%;
        width: 90%;
        padding-top: 64px;
        padding-left: 56px;
        padding-right: 56px;
        /* #FFFFFF */
        background: #FFFFFF;
        /* DS 01 */
        box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.08);
        border-radius: 48px;
    }

    .home_quotes_element {
        width: 100%;
        height: 100%;
        border: 1px solid #EFEFF0;
        box-sizing: border-box;
        border-radius: 48px;
        margin-right: 2%;
    }

    .home_iframe_style {
        border-radius: 48px;
        box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.08);
        width:100%;
        max-height: 260px;
        border: '10px solid #ffffff'
    }

}

/* @albasa: mobil nézet */
@media only screen and (max-width: 600px) {


}
