.notification_container {
    display: flex;
    flex-direction: row;
    width: 407px;
    height: 79px;
    left: 16px;
    top: 16px;
    /* #FFF5E6 */
    border-radius: 32px;

    align-content: center;
    align-items: center;
    padding:15px 20px;
}

.notification_container_alt {
    display: flex;
    flex-direction: row;
    width: 350px;
    height: 108;
    /*left: 16px;
    top: 16px;*/
    /* #FFF5E6 */
    border-radius: 32px;

    /*align-content: center;
    align-items: center;*/
    padding:15px 20px;
}

.notification_left_icon {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
}


.notification_notification_container_color_yellow{
    background: #FFF5E6;
}

.notification_notification_container_color_red{
    background: #FEE6E6;
}

.notification_notification_container_color_green{
    background: #E7F9F0;
}

.notification_notification_container_color_white{
    background: #FFFFFF;

}

.notification_notification_container_color_white_with_shadow{
    background: #FFFFFF;
    border: 3px solid #FFF5E6;
    box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.08);
}

.notification_lefticon_pic_bell {
    width: 40px;
    height:40px;
    /* #FF9901 */
    border-radius: 50%;
    padding: 12px 13px;
    align-content: center;
    align-items: center;
}


.notification_lefticon_pic_exclamation_alt {
    width: 40px;
    height:40px;
    /* #FF9901 */
    border-radius: 50%;
    padding: 12px 11px;
    align-content: center;
    align-items: center;
}

.notification_lefticon_color_orange {
    background: #FF9901;
}

.notification_lefticon_color_red {
    background: var(--red);
}

.notification_lefticon_color_green {
    background: #13C767;
}

.notification_lefticon_pic.ejicon-bell:before {
    font-size: 16px;
    /* #FFF5E6 */
}

.notification_lefticon_pic.ejicon-exclamation-alt:before {
    font-size: 16px;
    /* #FFF5E6 */
}

.notification_lefticon_pic_color.ejicon-bell:before {
    color: #FFF5E6;
}

.notification_lefticon_pic_color.ejicon-exclamation-alt:before {
    color: #FFF5E6;
}

.notification_lefticon_pic_color.ejicon-tick:before {
    color: #FFF5E6;
}

.notification_text_container {

    width: 100%;
    padding-left: 16px;
}

.notification_text_container_alt {

    width: 100%;
    padding-left: 10px;
}

.notification_text_title_color_orange {
    /* #FF9901 */
    color: #FF9901;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
}

.notification_text_title{
    flex-grow: 10;
}

.notification_text_title_color_red {
    /* #FF9901 */
    color: var(--red);
}

.notification_text_title_color_green {
    /* #FF9901 */
    color: #13C767;
}

.notification_text_desc {
    /* #646568 */
    color: #646568;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 3px 0px;
    
}

.notification_text_date {
    /* #646568 */
    color: #646568;
    padding-top: 12px;
}

.notification_righticon {
    padding-right: 6px;
    padding-left: 36px; 
}

.notification_righticon_color_orange
{
    color: #FF9901;
}

.notification_righticon_color_red
{
    color: var(--red);
}

.notification_righticon_color_green
{
    color: #13C767;
}

.notification_righticon_pic.ejicon-bell:before {
    font-size: 16px;
    /* #FF9901 */
    transform: rotate(45deg);
}

.notification_dot {
    width: 8px;
    height:8px;
    /* #FF9901 */
    border-radius: 50%;
    align-content: center;
    align-items: center;
    background: #FF9901;
}