.input_container {
    background: #FFFFFF;
    border-radius: 24px;
    border: 1px solid #EFEFF0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
    padding-top: 6px;
    padding-bottom: 6px;
    height: 56px;
}



.input_container:hover:not(.input_disabled, .input_error) {
    border: 1px solid #D2D4D9;
}

.input_container.input_disabled {
    background-color: #EFEFF0;
}

.input_container.input_error {
    border: 1px solid var(--red);
}

.input_field_container{
    /* width: 100%; */
    flex-basis:  20px;
    flex-shrink: 1;
    overflow: hidden;
}


.input_field { 
    flex-shrink: 1; 
    outline: none;
    border: none;
    overflow: hidden;
    white-space: nowrap;
    width:100%;
}

.input_container:active:not(.input_disabled) {
    filter: drop-shadow(16px 16px 60px rgba(0, 0, 0, 0.08));
    border: 1px solid #FFFFFF;
}

.input_field:focus:not(disabled) {
    font-weight: 600;
    border: 1px solid #FFFFFF;
    border-radius: 24px;
    outline: none;
}


.input_field:disabled {
    color: #A9A8AE;
    background-color: #EFEFF0;
    border: 1px solid #EFEFF0;
    box-sizing: border-box;
    border-radius: 24px;
}

.input_label {
    color: #A9A8AE;
    margin-bottom: 0;
}

.input_text_container {
    flex: 1 1 auto;
    overflow: hidden;
}

.icon_left {
    font-size: 16px;
    color: #A9A8AE;
    margin-right: 12px;
}

.icon_right {
    font-size: 16px;
    color: #A9A8AE;
}

.input_error_message {
    color: var(--red);
}

.input_dropdown_icon {
    color: var(--red);
}

.dropdown_hidden {
    display:none;
}

.dropdown_container {
    position: absolute;
    z-index: 20;
}

.mapped_tags_container {
    display: inline-flex;
    flex-direction: row;
}

.disabled_dropdown_icon {
    color: #A9A8AE
}