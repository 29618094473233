.tag_active {
    transform: translateY(-8px);
}

.tag_container {
    height: auto;
}
.profile-sidebar {
    background: #F5F6F6;
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
    position: relative;
}
.profile-sidebar::after {
    content: "";
    position: absolute;
    top: 0;
    right:-800px;
    background: #F5F6F6;
    width: 800px;
    height: 100%;
}
.profile-sidebar .card {
    box-shadow: none;
}
.profile-sidebar .notification_notification_container_color_white_with_shadow , .notification_container_alt{
    width: 100%!important;
    box-shadow: none;
    margin-bottom: 1.5rem;
}
.profile-page-pic-container {
    position: relative;
    width: 88px;
    height: 88px;
    padding: 0;
    
}
.profile-page-pic-container img {
    border-radius: 50%;
    object-fit: cover;
    width:100%;
    height:100%;
}
.profile-page-pic-container button {
    width: 32px!important;
    height: 32px!important;
    position: absolute;
    bottom: 0;
    right: 0;
}
.profile-page-pic-container .header_letters {
    width: 88px;
    height: 88px;
    font-size: 50px;
}
.profile-iconholder {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F5F6F7;
}
.profile-iconholder img {
    width: 24px;
}
.interests-selected {
    position: relative;
    width: 106px;
    margin: auto;
}
.profile-interests-container {
    text-align: center;
}
.profile-interests-container .small {
    font-size: 0.7em!important;
}
.profile-interests-container > div > img {
    border-radius: 32px;
}
.profile-interests-container .interests-selected::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 106px;
    height: 106px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(8px);
    border-radius: 32px;
}
.interests-selected button{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.profile-interests-container-selected p {
    font-weight: 600;
}
.profile-honors-badge {
    font-size: 32px;
}

.resize-img-size {
    width: 108px;
    height: 108px;
    object-fit: cover;
}
.profile-sidethings-container .btn_component {
    padding: 11px 28px;
}
.profile-sidethings-container .loader {
    width: 48px;
    height: 48px;
}
.profile-sidethings-container .text {
    padding-top: 22%;
}
.profile-sidethings-container h6 {
    font-size: 12px;
    font-weight: 500;
}
.justified-card-on-mobile {
    margin-bottom: 24px;
}
.profil_badge  {
    border-radius: 48px;
}
.profil_badge > img {
    border-radius: 48px;
    box-shadow: 16px 16px 60px rgb(0 0 0 / 8%);
    background: none;
}
@media (max-width: 778px) {
    .resize-img-size {
        width: 76px;
        height: 76px;
        object-fit: cover;
    }
    .profile-sidebar {
        background: transparent;
    }
    

}
@media (max-width: 425px) {
.justified-card-on-mobile {
    margin-left: -1rem;
    margin-right: -1rem;
}
}

#upload-photo {
    display: none;
  }