.eventcard_container {
    min-width: 300px;
    display: flex;
    flex-direction: column;
    overflow:hidden;
    border-radius: 40px;
}

.eventcard_container:not(.container_foundation){

}

.container_foundation{

}
.eventcard_img_elements:not(.eventcard_img_elements_foundation){
    position: relative;
 
}

.eventcard_img_elements_foundation{
    position: relative;
}

.eventcard_img {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: -1;
    width: 100%;
}

.eventcard_img > img {
    object-fit: cover;
    max-height:460px;
}

.eventcard_bookmark {
    float: right !important;
    padding: 16px !important;
    text-align: right;
    text-align: -webkit-right;
    text-align: -moz-right;
    text-align: -o-right; 
    text-align: -ms-right;
}
.eventcard_textbox {
    /* #FFFFFF */
    background: #FFFFFF;
    border-radius: 32px;
    padding-left:24px;
    padding-bottom: 16px;
}
.eventcard_desc {
    padding-top: 8px;
    color: #A9A8AE;
}

.eventcard_tags {
    padding-top: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.eventcard_tags > * {
    margin-right: 16px;
    margin-bottom: 8px;
}

.eventcard_avatars_container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.eventcard_avatar {
    
    margin-left: -7px;
    width: 28px;
    height: 28px;
}

.eventcard_avatar > img {
    width:100%;
    border: 2px solid #FFFFFF;
}

.eventcard_presentors {
    color: #646568;
    margin: 8px 0px;
}

.eventcard_schedule{
    margin: 26px 0px 18px 0px;
}

.eventcard_foundation{
    margin: 26px 0px 18px 0px;
}

.eventcard_schedule_icon {
    margin-right: 13px;
}

.event_details_modal_content > .modal-content {
    border: 0px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 44px 60px rgba(0, 0, 0, 0.05);
    border-radius: 32px;
}

.event_details_img {
    object-fit:cover;
}

.event_details_img_container {
    overflow: hidden;
}
.event_popup_back {
    color: #F40009;
    transform: rotate(180deg); 
}
@media only screen and (max-width: 600px) {
    /* For mobile phones: */
    .eventcard_container {

    }

    .eventcard_img_elements{
        position: relative;
        width:100%;
    }
  
}