
.modulcard_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 40px;
}

.modulcard_img_elements{
    position: relative;
}

.modulcard_img {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: -1;
    width: 100%;
}
.modulcard_img img {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    
}
.modulcard_status {
    float: left !important;
    margin:24px !important;
}

.modulcard_bookmark {
    float:right !important;
    padding:16px !important;
    left:75% !important;
}

.modulcard_completion {
    position: absolute !important;
    /* bottom: 13px; */
    left: 6.25% !important;
    width: 87.5% !important;
    height: 16px !important;
    
    background: #FFFFFF !important;
    /* DS 01 */
    box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.08) !important;
    border-radius: 32px !important;
    padding:4px !important;
    display: flex !important;
    flex-direction: row !important;
    top:88% !important;
}
.modulcard_completion_max {
    width: 80% !important;
    height: 100% !important;
}

.modulcard_completion_status {
    background: #FF9901 !important;
    border-radius: 32px !important;
    height: 100% !important;
}

.modulcard_completion_no {
    height: 100%;
    width: 20%;
    color: #FF9901;
    margin-top:-5px;
    text-align: right;
    font-size: 12px;
}

.modulcard_textbox {
    
    /* #FFFFFF */
    background: #FFFFFF;
    border-radius: 32px;
    padding-left:24px;
    padding-bottom: 16px;
}


.modulcard_stats {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:100%;
    padding-top: 20px;
    padding-bottom: 12px;
    padding-right: 25px;
    color: #646568;
}

.modulcard_stats_cell {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.modulcard_stars > * {
    margin-right: 12px;
}

.modulcard_stars {
    padding-left: 4px;
    white-space: nowrap;
}

.modulcard_viewers_icon {
    padding-right: 13px;
}

.modulcard_stats_cell>.ejicon-user::before {
    font-size: 16px;
    color: #646568;
}

.modulcard_desc {
    padding-top: 8px;
    color: #A9A8AE;
}

.modulcard_tags {
    padding-top: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.modulcard_tags > * {
    margin-right: 16px;
    margin-bottom: 8px;
}

@media (min-width: 0px) {
    .modulcard_img_elements{
        position: relative;
       
    }
}

@media (min-width: 540px) {
    .modulcard_img_elements{
        position: relative;
        /*height: 160px;*/
    }
}

@media (min-width: 720px) {
    .modulcard_img_elements{
        position: relative;
     
    }
}

@media (min-width: 960px) {
    .modulcard_img_elements{
        position: relative;
       
    }
}

@media (min-width: 1140px) {
    .modulcard_img_elements{
        position: relative;
        
    }
}

.modulcard_container:hover {
    cursor: pointer;
}

.modulcard_circle_container {
    width: fit-content;
    border-radius: 50%;
}

.not_bookmarked {
    stroke: var(--red);
    stroke-width: 2;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: var(--red);
}