.us {
    text-align: center;

}

.us_title {
    text-align: center;
}

.us_form {
    background: #FFFFFF;
    box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.08);
    border-radius: 32px;
   margin-top:15%;
}

.us_form_inputs {
    padding-top: 3vw;
    padding-left: 3vw;
    padding-bottom: 3vw;
    padding-right:3vw;
}

.us_img {
    width:100%;
    
} 

.us_img > img {
    width: 80%;
    border-radius: 50%;
}

.us_kapcsolat_kep {
    position: relative;
}

.us_form_img {
    position: absolute;
    top: -15%;
    left:10%;
    z-index: 2;
    width: 87%;
}

.us_form_img > img {
    width:110%;
    left: 10%;
    z-index: -1;
}

.us_form_telephone {
    position:absolute;
    top: 0%;
    left: 0%;
    padding: 24px;
    background: #ffffff;
    border-radius: 50%;
    box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.08);
    width: 88px;
    height: 88px;
    margin-top: -10%;
    margin-left: -3%;
    z-index: 999 !important;
}

.us-full-width-container2 {
    position:relative;
    overflow:visible;
    margin-bottom: 10%;
}
.us-full-width-container2 .us_bg-vector {
    position: absolute;
    left: 0;
    width: 100vw;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.us_auto_center {
    margin-top: auto;
    margin-bottom: auto;
}

.us_stat_element_icon {

}

.us_title > h2 {
    line-height: 30px;
}