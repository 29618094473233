.new-large-radio-container {
    position: relative;
}
.new-large-radio-container .content-div {
    position: absolute;
    bottom: 32px;
    left: 32px;
    right: 32px;
    color: white;
}
.new-large-radio-container .content-div h2 {
    color: white;
}
.new-full-width-container {
    position: relative;
}
.new-full-width-container .bg-vector {
    position: absolute;
    left: 0;
    width: 100vw;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}
.new-full-width-container .row {
    z-index: 2;
    position: relative;
}

.iframe_style {
    border-radius: 48px;
    box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.08); 
    border: 10px solid #ffffff;
}

.iframe_style_1 {
    border-radius: 48px;
    box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.08); 
    border: 5px solid #ffffff;
    background-color: #ffffff;
}

.iframe_style_2 {
    border-radius: 32px;
    background-color: #ffffff;
}

.text_center_all_events {
    align-items: center;
    text-align: center;
    color: #F40009!important;
    width:100%;
}
.newscard_container {
    cursor: pointer;
}

@media (max-width: 992px) {
    .new-large-radio-container {
        vertical-align: top;
        overflow: hidden;
    }
    .new-large-radio-container > img {
        max-height: 525px;
        object-fit: cover;
    }
    .new-large-radio-container .content-div h2 {
        color: white;
        line-height: 32px;
    }
}