.dashboard_h2 {
    font-weight: 400;
}

.dashboard_h2 > span {
    font-weight: 700;
}

.cc-project-radio {
    position: relative;
}
.cc-project-radio .backdrop-img {
    width: 100%;
}
.cc-project-radio h1 {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 32px;
}
.cc-project-radio p {
    font-size: 18px;
    color: #FFFFFF;
}
.cc-project-radio .content {
    position: absolute;
    left: 48px;
    bottom:48px;
    max-width: 468px;
}

.dashboard-container > img {
    position: absolute;
    z-index:-100;
    width: 110%;
    margin-left:-12px;
}

@media (max-width: 1400px) {
    .cc-project-radio .backdrop-img {
        height: 477px;
        object-fit: cover;
        border-radius: 32px;
    }
}

@media (max-width: 778px) {
    .resize-img-size {
        width: 76px;
    }
    .cc-project-radio .backdrop-img {
        height: 477px;
        object-fit: cover;
        border-radius: 32px;
    }
    .cc-project-radio .content {
        left: 16px;
        right: 16px;
    }
    .podcast-button-thing button {
        width: 100%;
    }
 }
.dashboard-container {
    
}
.sidebar-iconholder {
    width: 48px;
    height: 48px;
    min-height: 48px;
    min-width: 48px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sidebar-iconholder-grey {
    background: #F5F6F7;
}
.sidebar-iconholder-blue {
    background: #E6F1FE;
}
.sidebar-iconholder img {
    width: 32px;
}

.progress_container_mobile {
     margin-left: 10px;
}

.progress_container_mobile > div > .loader {
    width:56px;
    height: 56px;
}

.progress_container_mobile > div > .loader > .loader-bg > .text {
    padding-top: 23%;
}


