button.btn_component {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 28px;
    border-radius: 48px;
    border: none;
    transition: 0.25s all linear;
}



button.btn_component > p{
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 8px;
}

button.btn_primary {
    background: var(--red);  
    color: #FFFFFF;
}

button.btn_secondary {
    color: var(--red);
    background: #FFFFFF;
    border: 2px solid #D4D6DB;
    box-sizing: border-box;
    border-radius: 48px;
}

button.btn_primary:hover:not(.btn_disabled) {
    transform: translateY(-8px);
    color: #FFFFFF;
}

button.btn_secondary:hover:not(.btn_disabled) {
    border: 2px solid var(--red);
    box-sizing: border-box;
    border-radius: 48px;
}

button.btn_primary.btn_disabled {
    background: #D4D6DB;  
}

button.btn_secondary.btn_disabled {
    color: #D4D6DB;  
}

button.btn_secondary.btn_disabled > img {
    color: #D4D6DB;  
}


button.circle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 16px; 
    border: none;
    border-radius: 48px;
    width: 56px;
    height: 56px;
}

button.circle_white {
    box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.12);
    background: #FFFFFF;
    color: var(--red);
}

button.circle_white:hover {
    box-shadow: none;
    background: #FFFFFF;
}

button.circle_white.circle_disabled {
    background: #D4D6DB;
    box-shadow: none;
    color: white;
}

button.circle_bordered {
    border: 2px solid #D4D6DB;
    background: #FFFFFF;
    color: var(--red);
}

button.circle_bordered:hover:not(.circle_disabled) {
    border: 2px solid var(--red);
    background: #FFFFFF;
}

button.circle_bordered.circle_disabled {
    color: #D4D6DB;
    background: #FFFFFF;
}

button.circle_red {
    background: var(--red);
    color: #FFFFFF
}

button.circle_red:hover:not(.circle_disabled) {
    box-shadow: 8px 8px 24px rgba(244, 0, 0, 0.25);
}


button.circle_red.circle_disabled {
    background: #D4D6DB;
    color: #FFFFFF
}

/* Star (Csillagozás) button */
button.star {
    border: none;
    background: none;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
}

button.star_filled {
    /*box-shadow: 16px 16px 60px rgba(0, 0, 0, 0.12);*/
    color: #F5DD00;
    cursor:default;
}

button.star_filled>.ejicon-star::before {
    font-size: 14px;
}


button.star_filled:hover {
    box-shadow: none;
}

button.star_empty {
    
}

button.star_empty>.ejicon-star::before {
    font-size: 14px;
    color: #F5DD00;
    -webkit-text-fill-color: white; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #F5DD00;
    
}



